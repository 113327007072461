import React from "react";
import "./About.css";

const About = () => {
    return (
        <div className="about">
            <div className="div">
                <div className="overlap">
                    <img className="rectangle" alt="Rectangle" src="https://c.animaapp.com/Z0qQk4G9/img/rectangle-2.png" />
                    <p className="about-us">
                        <span className="text-wrapper">A</span>
                        <span className="text-wrapper">bout </span>
                        <span className="text-wrapper">U</span>
                        <span className="text-wrapper">s</span>
                    </p>
                    <div className="flexcontainer">
                        <p className="text">
                            <span className="span">
                                Lorem Ipsum is simply dummy text of the printing and
                                <br />
                            </span>
                        </p>
                        <p className="text">
                            <span className="span">typesetting industry. Lorem Ipsum has been.</span>
                        </p>
                    </div>
                    <div className="ellipse" />
                    <div className="ellipse-2" />

                </div>

                <div className="group-3">
                    <div className="flexcontainer-2">
                        <p className="text-2">
                            <span className="text-wrapper-12">Q</span>
                            <span className="text-wrapper-12">
                                uisque eu nunc convallis iaculis
                                <br />
                            </span>
                        </p>
                        <p className="text-2">
                            <span className="text-wrapper-12">
                                ante ut, posuere diam Morbi quis
                                <br />
                            </span>
                        </p>
                        <p className="text-2">
                            <span className="text-wrapper-12">nisi porta, placerat lorem at</span>
                        </p>
                    </div>
                    <div className="flexcontainer-3">
                        <p className="span-wrapper">
                            <span className="text-wrapper-13">
                                Lorem ipsum dolor sit amet consectetur. Nam ridiculus dictum lacus odio et proin. Nunc eleifend egestas
                                ornare risus tortor ut facilisis viverra et duis feugiat.
                                <br />
                            </span>
                        </p>
                        <p className="span-wrapper">
                            <span className="text-wrapper-13">
                                Nisl aliquam sit cras posuere nibh dictumst sed. Nulla in aliquam eu orci. Quisque mattis massa
                                vulputate purus sit ornare eget.
                            </span>
                        </p>
                    </div>
                </div>
                <div className="overlap-wrapper">
                    <div className="overlap-3">
                        <div className="overlap-4">
                            <img
                                className="rectangle-3"
                                alt="Rectangle"
                                src="https://c.animaapp.com/Z0qQk4G9/img/rectangle-4839.png"
                            />
                            <img
                                className="rectangle-4"
                                alt="Rectangle"
                                src="https://c.animaapp.com/Z0qQk4G9/img/rectangle-4840.png"
                            />
                        </div>
                        <div className="group-4">
                            <div className="text-wrapper-14">OUR MISSION &amp; VISION</div>
                            <p className="text-wrapper-15">
                                Lorem ipsum dolor sit amet consectetur. Leo neque duis id tristique netus curabitur. A fermentum
                                pellentesque nisi convallis ultrices ornare maecenas non habitant. Cursus feugiat amet a quis quis leo
                                luctus leo venenatis. Arcu nisl in dolor morbi. Aliquam mauris congue scelerisque tellus. Phasellus
                                elementum feugiat semper ipsum nisl est risus. Ipsum maecenas vivamus laoreet vel. Ut eleifend sed
                                iaculis faucibus porta eu ut id euismod.
                            </p>
                        </div>
                        <div className="group-5">
                            <div className="text-wrapper-14">WHY US</div>
                            <p className="text-wrapper-15">
                                Lorem ipsum dolor sit amet consectetur. Leo neque duis id tristique netus curabitur. A fermentum
                                pellentesque nisi convallis ultrices ornare maecenas non habitant. Cursus feugiat amet a quis quis leo
                                luctus leo venenatis. Arcu nisl in dolor morbi. Aliquam mauris congue scelerisque tellus. Phasellus
                                elementum feugiat semper ipsum nisl est risus. Ipsum maecenas vivamus laoreet vel. Ut eleifend sed
                                iaculis faucibus porta eu ut id euismod.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;