


// Import other admin components as needed

import AdminOverView from "../../AdminPanel/AdminOverView/AdminOverView";
import CommisionList from "../../SuperAdminPanel/CommisionList";
import SuperAdminEventList from "../../SuperAdminPanel/SuperAdminEvents/SuperAdminEventsList";
import SuperAdminOverView from "../../SuperAdminPanel/SuperAdminOverview";
import ViewReport from "../../SuperAdminPanel/SuperAdminReports/ViewReport";
import SuperAdminReportList from "../../SuperAdminPanel/SuperAdminReports/SuperAdminReportList";

const SuperAdminRoutes = [
    { path: "/superadmin/overview", element: <SuperAdminOverView /> },
    { path: "/superadmin/commision", element: <CommisionList /> },
    { path: "/superadmin/events", element: <SuperAdminEventList /> },
    { path: "/superadmin/reports", element: <SuperAdminReportList /> },
    { path: "/superadmin/reports/view/:id", element: <ViewReport /> },
    // Add more admin routes as needed
];

export default SuperAdminRoutes;
