import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import useFetch from '../../Helper/useFetch';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Constants from '../../Components/UiKit/Constants';
import useDelete from '../../Helper/useDelete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const AdminEventList = () => {
    // const [data, setData] = useState([]);
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate()
    const deleteItem = useDelete()


    const [data, isPLoading, getData] = useFetch(`${apiUrl}admin/event`, [], true);


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'Event Date',
            dataIndex: 'eventDate',
            key: 'eventDate',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
    ];

    const getImgUrl = (url) => {

        if (url?.startsWith('/')) {
            url = url?.substring(1)
            return apiUrl + url
        }
        return apiUrl + url

    }

    const handleDeleteEvent = (id) => {
        // deleteItem(`${apiUrl}admin/event/${id}`, getData, `${apiUrl}admin/event`)
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { deleteItem(`${apiUrl}admin/event/${id}`, getData, `${apiUrl}admin/event`) }
                },
                {
                    label: 'No',
                    onClick: () => { }, // Do nothing on "No" click
                },
            ],
        });
    }
    const truncateDescription = (description, limit = 10) => {
        const words = description.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        }
        return description;
    };

    return (
        <>


            {/* eventBanner */}

            <div className='adminEventSearch'>
                <div>
                    <img src={Constants.EVENTSEARCHICON} alt="Search Icon" />
                    <input type="text" placeholder="Search" />
                </div>
                <div>
                    {/* <div className='addEventDiv'> */}
                    <Nav className='addEventDiv'>
                        <Nav.Link
                            to="/admin/events/add"
                            onClick={() => navigate("/admin/events/add")}
                            className='themeBtn'

                        >Add Event</Nav.Link>
                    </Nav>
                    {/* </div> */}
                </div>
            </div>

            <div className="eventList">
                <div className='ongoingEventSection'>
                    <Container>
                        <Row>
                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-4'>
                                    <div className='eventBox'>
                                        <div className='contestantBox'>Paid</div>
                                        <button type="button" className="eventEditBtn" style={{ right: '50px' }} onClick={() => handleDeleteEvent(i.id)}> <img src={Constants.DELETEICON} /> </button>
                                        <button type="button" className="eventEditBtn" onClick={() => navigate(`/admin/events/edit/${i.id}`)}> <img src={Constants.EDITICON} /> </button>
                                        <img src={getImgUrl(i.image)} alt="event" crossOrigin="anonymous" className='adminEventImg' />

                                        <div className='eventNameBox'>
                                            <div className='adminEventName'>
                                                <h4>{i.name}</h4>
                                            </div>
                                            <div className='eventStatus'>
                                                <div className='statusBox'>Rejected</div>
                                            </div>
                                        </div>

                                        <div className='adminEventDtls'>
                                            {/* <div className='dateBox'>
                                                <span>{new Date(i.fromTime).toLocaleString('default', { month: 'short' })}</span>
                                                {new Date(i.fromTime).getDate()}
                                            </div> */}
                                            {/* <div>
                                                {new Date(i.fromTime).toLocaleTimeString()}
                                            </div> */}

                                            <div>
                                                {/* <h5>{i.name}</h5> */}
                                                <p>t{truncateDescription(i.description)}</p>
                                            </div>
                                        </div>

                                        <div className='adminTag'>
                                            <div onClick={() => navigate("/admin/category")}>Category</div>
                                            <div onClick={() => navigate("/admin/edition")}>Edition</div>
                                        </div>
                                        <button className='openFormsBtn'>Open Forms</button>
                                        {/* <button className='themBtn'>Activate</button> */}
                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </Container>

                </div>
            </div>


        </>);
};

export default AdminEventList;
