import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const useApiPost = (url) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null);

    const postData = async (data, requestType = "POST") => {
        setIsLoading(true);
        setError(null);
        try {
            const token = process.env.TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA';
            const response = await fetch(url, {
                method: requestType,
                headers: {

                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                // throw new Error('Network response was not OK');
                // console.log('Network response was not OK')
            }

            const jsonData = await response.json();
            if (jsonData.status === 401 || !jsonData.success) {
                // console.log("msg-", jsonData.message)
                toast.error(jsonData.message)
            }
            setResponseData(jsonData);
            if (jsonData.status === 200 || jsonData.success) {
                toast.success(jsonData.message)

            }

        } catch (error) {
            // console.log("ERROR", error)
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return [isLoading, error, responseData, postData];
};

export default useApiPost;