import React, { useEffect, useState } from "react";
import "./ExploreEvents.css";
import { Navigate, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Constants from "../../../Components/UiKit/Constants";
import useFetch from "../../../Helper/useFetch";
import PathConstants from "../../../Helper/Routes/PathConstants";

const ExploreEvents = () => {
    const navigate = useNavigate()
    const apiUrl = process.env.REACT_APP_VOICE_API
    const [name, setName] = useState("")
    const [data, isPLoading, getData] = useFetch(`${apiUrl}event`, [], true);
    const handleEvents = () => {
        getData(`${apiUrl}event?name=${name}`)
    }

    useEffect(() => {
        getData(`${apiUrl}event?name=${name}`)
    }, [name])

    return (
        <>
            <div className="eventBanner">
                <div className="container text-center">
                    <h3>Explore Events</h3>
                    <div className="event-search-banner">
                        {/* <div>
                            <img src={Constants.EVENTLOCATIONICON} alt="Search Icon" />
                            <input type="text" placeholder="Enter Address or Online"></input>
                        </div> */}
                        <div>
                            <img src={Constants.EVENTSEARCHICON} alt="Search Icon" />
                            <input type="text" placeholder="Enter Anything" style={{ minWidth: '219px' }} onChange={(event) => setName(event.target.value)}></input>
                        </div>
                    </div>
                    <a class="themeBtn" onClick={handleEvents}>Find Event</a>
                </div>
            </div>
            {/* eventBanner */}

            <div className="eventList">
                <div className='ongoingEventSection frontEndOngingEvent sectionPadding'>
                    <h2 className='heading'>Parties and Events</h2>
                    <Container>
                        <Row>
                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-4'>
                                    <div className='eventBox'>
                                        <div className='contestantBox'>Paid</div>
                                        <button type="button" className="themeBtn voteBtn" onClick={() => navigate(PathConstants.EVENTSDETAIL.replace(":id", i.id))}>Vote</button>
                                        <img src={apiUrl + i.image} alt="event" crossOrigin="anonymous" />
                                        <div className='eventDtls'>
                                            <div className='dateBox'>
                                                <span>{new Date(i.fromTime).toLocaleString('default', { month: 'short' })}</span>
                                                {new Date(i.fromTime).getDate()}
                                            </div>
                                            {/* <div>
                                                {new Date(i.fromTime).toLocaleTimeString()}
                                            </div> */}

                                            <div>
                                                <h5>{i.name}</h5>
                                                <p>{i.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}


                            {/* <Col className='col-12 col-sm-4 col-md-4'>
                                <div className='eventBox'>
                                    <div className='contestantBox'>Paid</div>
                                    <button type="button" className="themeBtn voteBtn">Vote</button>
                                    <img src={Constants.PARTIEEVENTS1} alt="event" />
                                    <div className='eventDtls'>
                                        <div className='dateBox'>
                                            <span>APR</span>
                                            14
                                        </div>
                                        <div>
                                            <h5>Wonder Girls 2010 Wonder Girls World Tour San Francisco</h5>
                                            <p>We’ll get you directly seated and inside for you to enjoy the show.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-sm-4 col-md-4'>
                                <div className='eventBox'>
                                    <div className='contestantBox'>Paid</div>
                                    <button type="button" className="themeBtn voteBtn">Vote</button>
                                    <img src={Constants.PARTIEEVENTS2} alt="event" />
                                    <div className='eventDtls'>
                                        <div className='dateBox'>
                                            <span>APR</span>
                                            14
                                        </div>
                                        <div>
                                            <h5>Wonder Girls 2010 Wonder Girls World Tour San Francisco</h5>
                                            <p>We’ll get you directly seated and inside for you to enjoy the show.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-sm-4 col-md-4'>
                                <div className='eventBox'>
                                    <div className='contestantBox'>Paid</div>
                                    <button type="button" className="themeBtn voteBtn">Vote</button>
                                    <img src={Constants.PARTIEEVENTS3} alt="event" />
                                    <div className='eventDtls'>
                                        <div className='dateBox'>
                                            <span>APR</span>
                                            14
                                        </div>
                                        <div>
                                            <h5>Wonder Girls 2010 Wonder Girls World Tour San Francisco</h5>
                                            <p>We’ll get you directly seated and inside for you to enjoy the show.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-sm-4 col-md-4'>
                                <div className='eventBox'>
                                    <div className='contestantBox'>Paid</div>
                                    <button type="button" className="themeBtn voteBtn">Vote</button>
                                    <img src={Constants.PARTIEEVENTS1} alt="event" />
                                    <div className='eventDtls'>
                                        <div className='dateBox'>
                                            <span>APR</span>
                                            14
                                        </div>
                                        <div>
                                            <h5>Wonder Girls 2010 Wonder Girls World Tour San Francisco</h5>
                                            <p>We’ll get you directly seated and inside for you to enjoy the show.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-sm-4 col-md-4'>
                                <div className='eventBox'>
                                    <div className='contestantBox'>Paid</div>
                                    <button type="button" className="themeBtn voteBtn">Vote</button>
                                    <img src={Constants.PARTIEEVENTS2} alt="event" />
                                    <div className='eventDtls'>
                                        <div className='dateBox'>
                                            <span>APR</span>
                                            14
                                        </div>
                                        <div>
                                            <h5>Wonder Girls 2010 Wonder Girls World Tour San Francisco</h5>
                                            <p>We’ll get you directly seated and inside for you to enjoy the show.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-12 col-sm-4 col-md-4'>
                                <div className='eventBox'>
                                    <div className='contestantBox'>Paid</div>
                                    <button type="button" className="themeBtn voteBtn">Vote</button>
                                    <img src={Constants.PARTIEEVENTS3} alt="event" />
                                    <div className='eventDtls'>
                                        <div className='dateBox'>
                                            <span>APR</span>
                                            14
                                        </div>
                                        <div>
                                            <h5>Wonder Girls 2010 Wonder Girls World Tour San Francisco</h5>
                                            <p>We’ll get you directly seated and inside for you to enjoy the show.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col> */}

                        </Row>
                    </Container>
                    <img src={Constants.VECTOR1} alt="event" className='vector1Img' />
                </div>
            </div>
        </>
    );
};

export default ExploreEvents