import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useDeleteApi = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const sendDeleteRequest = async (url) => {
		setIsLoading(true);
		setError(null);

		try {
			const token = localStorage.getItem('Dating-Token');
			const response = await fetch(url, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error('Request failed.');
			}

			// Request succeeded, handle response if necessary...

			// Show success toaster notification
			toast.success('Item deleted successfully!', {
				position: 'top-center',
			});

		} catch (error) {
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	return { isLoading, error, sendDeleteRequest };
};

export default useDeleteApi;
