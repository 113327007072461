import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewPostApi = (url, requestType = "POST") => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const postData = async (data, requestType) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA'
      if (!token) {
        throw new Error('No authorization token was found');
      }

      const response = await fetch(url, {
        method: requestType,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      // try {
      //   const token = localStorage.getItem('Dating-Token');
      //   const response = await fetch(url, {
      //     method: requestType,
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Bearer ${token}`,
      //     },
      //     body: JSON.stringify(data),
      //   });

      if (!response.ok) {
        // throw new Error('Network response was not OK');


        const jsonData = await response.json();
        if (jsonData.status === 400) {
          toast.error(jsonData.message);
        } else if (jsonData.status === 401) {

          toast.error(jsonData.message);
        } else {
          throw new Error('Network response was not OK');
        }
      }
      else {
        const jsonData = await response.json();
        setResponseData(jsonData);
        toast.success(jsonData.message);
      }

      // setResponseData(jsonData);
    } catch (error) {
      // console.log("ERROR", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addOrUpdateData = async (data, shouldUpdate) => {
    const requestType = shouldUpdate ? "PUT" : "POST";
    await postData(data, requestType);
  };

  return { isLoading, error, responseData, addOrUpdateData };
};

export default NewPostApi;
