import React, { useState } from 'react';
import { Table } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import useFetch from '../../Helper/useFetch';

const SuperAdminEventList = () => {
    const apiUrl = process.env.REACT_APP_VOICE_API
    const imgUrl = process.env.REACT_APP_VOICE_API


    const columns = [
        {
            title: 'Event code',
            dataIndex: 'organizer',
            key: 'organizer',
        },
        {
            title: 'Event Image',
            dataIndex: 'image',
            key: 'image',
            render: data => {
                return data ? <img src={`${imgUrl}${data}`} crossOrigin='anonymous' style={{ height: "75px", width: "100px" }}></img> : "NA"
            }
        },
        {
            title: 'Event Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total contestant of event',
            dataIndex: 'organizer',
            key: 'organizer',
        },
        {
            title: 'Organizer Name',
            dataIndex: 'organizer',
            key: 'organizer',
        },
        {
            title: 'Status',
            dataIndex: 'enabled',
            key: 'enabled',
            render: data => {
                return data ? "Active" : "Inactive"
            }
        },
        {
            title: 'Actions',
            dataIndex: 'organizer',
            key: 'organizer',
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [data, isPLoading, getData] = useFetch(`${apiUrl}admin/event`, [], true);

    return (
        <div>
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data?.data}
            />
            {/* Modal for Complaint Form */}
        </div>
    );
};

export default SuperAdminEventList;


// import React, { useState, useEffect } from 'react';
// import { Table } from 'antd';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import useFetch from '../../Helper/useFetch';
// import { useNavigate } from 'react-router-dom';
// import Container from 'react-bootstrap/Container';
// import { Row, Col } from 'react-bootstrap';
// import Constants from '../../Components/UiKit/Constants';
// import useDelete from '../../Helper/useDelete';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';


// const SuperAdminEventList = () => {
//     // const [data, setData] = useState([]);
//     const apiUrl = process.env.REACT_APP_VOICE_API;
//     const navigate = useNavigate()
//     const deleteItem = useDelete()


//     const [data, isPLoading, getData] = useFetch(`${apiUrl}admin/event`, [], true);


//     const columns = [
//         {
//             title: 'ID',
//             dataIndex: 'id',
//             key: 'id',
//         },
//         {
//             title: 'Event Name',
//             dataIndex: 'eventName',
//             key: 'eventName',
//         },
//         {
//             title: 'Event Date',
//             dataIndex: 'eventDate',
//             key: 'eventDate',
//         },
//         {
//             title: 'Time',
//             dataIndex: 'time',
//             key: 'time',
//         },
//     ];

//     const getImgUrl = (url) => {

//         if (url?.startsWith('/')) {
//             url = url?.substring(1)
//             return apiUrl + url
//         }
//         return apiUrl + url

//     }

//     const handleDeleteEvent = (id) => {
//         confirmAlert({
//             title: 'Confirm Delete',
//             message: 'Are you sure you want to delete this item?',
//             buttons: [
//                 {
//                     label: 'Yes',
//                     onClick: () => { deleteItem(`${apiUrl}admin/event/${id}`, getData, `${apiUrl}admin/event`) }
//                 },
//                 {
//                     label: 'No',
//                     onClick: () => { }, // Do nothing on "No" click
//                 },
//             ],
//         });
//     }
//     const truncateDescription = (description, limit = 10) => {
//         const words = description.split(' ');
//         if (words.length > limit) {
//             return words.slice(0, limit).join(' ') + '...';
//         }
//         return description;
//     };
//     const confirmApprovalAlert = (id) => {
//         confirmAlert({
//             title: 'Confirm Approval',
//             message: 'Are you sure you want to approve this event?',
//             buttons: [
//                 {
//                     label: 'Yes',
//                     onClick: () => approveEvent(id)
//                 },
//                 {
//                     label: 'No',
//                     onClick: () => { }, // Do nothing on "No" click
//                 },
//             ],
//         });
//     };

//     const approveEvent = (id) => {

//     }
//     const rejectEvent = (id) => {
//         // Here you can implement the logic for rejecting the event
//         // For example, you can make an API call to update the event status to "rejected"
//     }

//     const confirmRejectionAlert = (id) => {
//         confirmAlert({
//             title: 'Confirm Rejection',
//             message: 'Are you sure you want to reject this event?',
//             buttons: [
//                 {
//                     label: 'Yes',
//                     onClick: () => rejectEvent(id)
//                 },
//                 {
//                     label: 'No',
//                     onClick: () => { }, // Do nothing on "No" click
//                 },
//             ],
//         });
//     };


//     return (
//         <>



//             <div className='adminEventSearch'>
//                 <div>
//                     <img src={Constants.EVENTSEARCHICON} alt="Search Icon" />
//                     <input type="text" placeholder="Search" />
//                 </div>
//                 <div>

//                 </div>
//             </div>

//             <div className="eventList">
//                 <div className='ongoingEventSection'>
//                     <Container>
//                         <Row>
//                             {data?.data.map(i => (
//                                 <Col key={i.id} className='col-12 col-sm-4 col-md-4'>
//                                     <div className='eventBox'>
//                                         <div className='contestantBox'>Paid</div>
//                                         <button type="button" className="eventEditBtn" style={{ right: '50px' }} onClick={() => handleDeleteEvent(i.id)}> <img src={Constants.DELETEICON} /> </button>
//                                         <img src={getImgUrl(i.image)} alt="event" crossOrigin="anonymous" className='adminEventImg' />

//                                         <div className='eventNameBox'>
//                                             <div className='adminEventName'>
//                                                 <h4>{i.name}</h4>
//                                             </div>
//                                             <div className='eventStatus'>
//                                                 <div className='statusBox' onClick={() => confirmApprovalAlert(i.id)} style={{ color: "#cac931" }} > Approve</div>
//                                                 <div className='statusBox' onClick={() => confirmRejectionAlert(i.id)}  >Reject</div>
//                                             </div>
//                                         </div>

//                                         <div className='adminEventDtls'>


//                                             <div>
//                                                 <p>t{truncateDescription(i.description)}</p>
//                                             </div>
//                                         </div>


//                                         <button className='openFormsBtn'>Open Forms</button>

//                                     </div>
//                                 </Col>
//                             ))}

//                         </Row>
//                     </Container>

//                 </div>
//             </div >


//         </>);
// };

// export default SuperAdminEventList;
