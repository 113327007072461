import React from "react";
import { Form, Input, Button } from "antd";
import "./ContestantVote.css";
import { useParams, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import "../LoginSignup/LoginSignup.css"
import NewPostApi from "../../Helper/NewPostApi";


const ContestantVote = () => {
    const { id } = useParams()
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const categoriesToUpdate = NewPostApi(`${apiUrl}vote`)
    const location = useLocation();
    const firstname = location?.state?.firstname;
    const lastname = location?.state?.lastname;
    const image = location?.state?.image;
    const shortCode = location?.state?.shortCode;
    const onFinish = async (values) => {
        // Handle form submission here
        try {
            await categoriesToUpdate.addOrUpdateData({ ...values, "contestantId": parseInt(id) }, false);



        } catch (error) {

            console.error(error);
        }
    };

    return (

        <div className="vote">


            <div className="bgTop">

                <h1 className="categoryHeading">Best Outside Hitter Men</h1>
                {/* <img alt="Rectangle" src="https://c.animaapp.com/D2VowqH2/img/rectangle-2.png" style={{ "width": "100%" }} /> */}
            </div>
            <div className="containerForm">

                <Container>


                    <Row className='align-items-center'>
                        <Col className='col-12 col-sm-6 col-md-6'>
                            <div style={{ textAlign: "right" }}>
                                {/* <img className="rectangle-3" alt="Rectangle" src="https://c.animaapp.com/D2VowqH2/img/rectangle-21@2x.png" />                        </div> */}
                                <img className="constestantImg" crossOrigin="anonymous" alt="Rectangle" src={`${apiUrl}/api/${image}`} />
                            </div>

                        </Col>
                        <Col className='col-12 col-sm-6 col-md-6'>
                            <h1 className="voteName">Vote for {firstname} {lastname}</h1>
                            <p className="voteCategory">Contesting For Best Outside Hitter Men
                            </p>
                            <p className="voteCode">
                                SHORT CODE: {shortCode}
                            </p>

                            <Form
                                // name="contestantVoteForm"
                                onFinish={onFinish}
                                className="userLoginInput"
                            // className="group-3"
                            >

                                <Form.Item
                                    name="name"

                                    // className="text-wrapper-12"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your full name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Full Name" className="fullName" />
                                </Form.Item>

                                {/* <div className="overlap-wrapper"> */}
                                <Form.Item
                                    name="email"
                                    // className="group-4"
                                    rules={[
                                        {
                                            required: true,
                                            message: "EMAIL",
                                        },
                                    ]}
                                >
                                    <Input placeholder="EMAIL" className="fullName" />
                                </Form.Item>
                                {/* </div> */}
                                <Form.Item
                                    name="votes"
                                    // className="group-4"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Enter Votes (GHc1 per Vote)",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Votes (GHc1 per Vote" className="fullName" />
                                </Form.Item>



                                <Form.Item
                                // className="text-wrapper-10"
                                >
                                    <Button type="primary" htmlType="submit" className="voteButton">
                                        Vote
                                    </Button>
                                </Form.Item>
                            </Form>

                        </Col>

                    </Row>

                </Container>
            </div>
        </div>
    )


};

export default ContestantVote;
