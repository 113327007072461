import React, { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import useFetch from '../../Helper/useFetch';



const AdminContestantVotes = () => {
    const { id } = useParams();
    const { Option } = Select;
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const location = useLocation();
    const eventId = location.state && location.state.eventId;
    console.log({ eventId })
    const [selectedCategory, setSelectedCategory] = useState(0);
    // Sample data, replace it with your actual data
    const [data, isPLoading, getData] = useFetch(`${apiUrl}vote/edition/${id}?category=${selectedCategory}`, [], false);
    const [categoryData, isCLoading, getCategoryData] = useFetch(`${apiUrl}category/event/${eventId}`, [], true);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);

    };

    useEffect(() => {
        if (selectedCategory) {
            getData(`${apiUrl}vote/edition/${id}?category=${selectedCategory}`)

        }
    }, [selectedCategory])
    const columns = [

        {
            title: 'Contestant ID',
            dataIndex: 'contestant',
            key: 'contestant',
            render: (data) => (data ? data.id : null),
        },
        {
            title: 'First Name',
            dataIndex: 'contestant',
            key: 'contestant',
            render: (data) => (data ? data.firstname : null), // Add a return statement here
        },
        {
            title: 'Last Name',
            dataIndex: 'contestant',
            key: 'contestant',
            render: (data) => (data ? data.lastname : null), // Add a return statement here
        },
        // {
        //     title: 'Stage Name',
        //     dataIndex: 'stageName',
        //     key: 'stageName',
        // },
        {
            title: 'Short Code',
            dataIndex: 'contestant',
            key: 'contestant',
            render: (data) => (data ? data.shortCode : null),
        },
        {
            title: 'Votes',
            dataIndex: 'votes',
            key: 'votes',
        },
    ];

    return (
        <>
            <div className='overViewCountWrapper'>
                <div className='recentEvents'>
                    <div className='tableHead'>Votes </div>
                    <Select
                        style={{ width: 200 }}
                        placeholder="Select Category"
                        onChange={handleCategoryChange}
                    >
                        {categoryData?.data?.map(category => (
                            <Option key={category.id} value={category.id}>
                                {category.name}
                            </Option>
                        ))}
                    </Select>
                    <Table dataSource={data?.data} columns={columns} />
                </div>
            </div>
        </>
    );
};

export default AdminContestantVotes;



