import React, { useState } from 'react';
import { Table, Modal, Form, Input, Button, Upload } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const AdminVotingList = () => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'VoitingId',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Votes',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'GHC',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'email',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'Voter Name',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'Event',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'Rate',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'Status',
            dataIndex: 'eventId',
            key: 'eventId',
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        // Handle form submission here
        console.log('Received values:', values);
        setIsModalVisible(false); // Close the modal after submission
    };

    return (
        <div>
            <Nav className='addEventDiv'>
                {/* <Nav.Link to="/admin/events/add" className='themeBtn' onClick={showModal}>
                    Send a Complaint
                </Nav.Link> */}
                <button to="/admin/events/add" className='themeBtn' onClick={showModal}>
                    Download CSV
                </button>
            </Nav>
            <Table columns={columns} />

            {/* Modal for Complaint Form */}
            <Modal
                title="Send a Complaint"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form name="complaintForm" onFinish={onFinish}>
                    <Form.Item
                        name="subject"
                        label="Subject"
                        rules={[{ required: true, message: 'Please enter the subject!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please enter a description!' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        name="screenshot"
                        label="Attach Screenshot"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e && e.fileList}
                    >
                        <Upload name="logo" action="/upload.do" listType="picture">
                            <Button>Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AdminVotingList;
