import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./FrontEnd/Helper/Routes/index";
import Layout from "./FrontEnd/Components/Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AdminRoutes from './FrontEnd/Helper/Routes/AdminRoutes';
import AdminPanel from './FrontEnd/AdminPanel/AdminDashBoard';
import SuperAdminRoutes from './FrontEnd/Helper/Routes/SuperAdminRoutes';
import SuperAdminPanel from './FrontEnd/SuperAdminPanel/SuperAdminDashboard';



const App = () => {


  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: routes
    },
    {
      element: <AdminPanel />,
      children: AdminRoutes
    },
    {
      element: <SuperAdminPanel />,
      children: SuperAdminRoutes
    }
    // ...AdminRoutes
  ]);

  return <RouterProvider router={router} />;
}

export default App;

