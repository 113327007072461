import React, { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import useFetch from '../../Helper/useFetch';
import 'react-toastify/dist/ReactToastify.css';

const ViewReport = () => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const { id } = useParams();

    const [currentPage, setCurrentPage] = useState(1);
    const [data, isPLoading, getData] = useFetch(`${apiUrl}report/${id}?isRead=true`, [], true);

    const [initialValues, setInitialValues] = useState({
        type: '',
        title: '',
        message: '',
        image: '',
        userType: ''
    });

    const handleNotificationSubmit = () => {
        // Add your logic here
    };

    return (
        <div>
            <Container>
                <Row>
                    <Col className='col-12 col-sm-6'>
                        <div className="bg-white border p-3">
                            <Row>
                                <Col className='col-6 col-sm-6'>
                                    {/* <i className='fas fa-user'></i> */}
                                    <span>Name</span> :  {data?.data?.user?.name}
                                </Col>

                                <Col className='col-6 col-sm-12'>
                                    {/* <i className='fas fa-envelope'></i>  */}
                                    Email :    {data?.data?.user?.email ?? "NA"}
                                </Col>
                                <Col className='col-6 col-sm-12'><i className="fas fa-book-open"></i>  Subject: {data?.data?.subject}</Col>
                                <Col className='col-6 col-sm-12'><i className="fas fa-comment-alt"></i> Message: {data?.data?.description} </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ViewReport;
