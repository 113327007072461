// BankDetailsForm.js
import React from 'react';
import { Form, Input, Modal } from 'antd';

const BankDetailsForm = ({ visible, onClose, onSubmit }) => {
    const [form] = Form.useForm();

    const handleFinish = (values) => {
        onSubmit(values);
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title="Add Bank Account"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form form={form} onFinish={handleFinish}>
                <Form.Item name="bankName" label="Bank Name" rules={[{ required: true, message: 'Please enter bank name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="accountNumber" label="Account Number" rules={[{ required: true, message: 'Please enter account number' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="organizationId" label="Organization ID" rules={[{ required: true, message: 'Please enter organization ID' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please enter password' }]}>
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <button type="primary" htmlType="submit">
                        Submit
                    </button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BankDetailsForm;
