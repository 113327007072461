// AdminRoutes.js
import React from "react";
import EventDetail from "../../Pages/Home/Events/EventDetail";
import About from "../../Pages/About/About";
import AdminDashboard from "../../AdminPanel/AdminDashBoard";
import AdminEvents from "../../AdminPanel/AdminEvents/AdminEvents";
import AdminEventList from "../../AdminPanel/AdminEvents/AdminEventList";
import AdminCategoryList from "../../AdminPanel/AdminCategory/AdminCategoryList";
import AdminEditionList from "../../AdminPanel/AdminEdition/AdminEditionList";
import CategoriesList from "../../AdminPanel/AdminCategory/CategoriesList";
import Faqs from "../../AdminPanel/FAQS/Faqs";
import EditionList from "../../AdminPanel/AdminEdition/EditionList";
import Reports from "../../AdminPanel/Reports/Reports";
import Setting from "../../AdminPanel/Setting/Setting";
import AdminContestantsList from "../../AdminPanel/AdminContestants/AdminContestantsList";
import AdminOverView from "../../AdminPanel/AdminOverView/AdminOverView";
import AdminVotingList from "../../AdminPanel/AdminVoting/AdminVotingList";
import AdminWithDrawlsList from "../../AdminPanel/AdminWithdrawls/AdminWithDrawlsList";
import AdminContestantForEdition from "../../AdminPanel/AdminEdition/AdminContestantForEdition";
import AdminEditionResult from "../../AdminPanel/AdminEdition/AdminEditionResult";
import AdminContestantVotes from "../../AdminPanel/AdminContestants/AdminContestantVotes";



// Import other admin components as needed

const AdminRoutes = [
    { path: "/admin/dashboard" },
    { path: "/admin", element: <AdminOverView /> },
    { path: "/admin/overview", element: <AdminOverView /> },
    { path: "/admin/users", element: <About /> },
    { path: "/admin/events", element: <AdminEventList /> },
    { path: "/admin/events/add", element: <AdminEvents /> },
    { path: "/admin/events/edit/:id", element: <AdminEvents /> },
    { path: "/admin/category", element: <AdminCategoryList /> },
    { path: "/admin/categories/:id", element: <CategoriesList /> },
    { path: "/admin/edition", element: <AdminEditionList /> },
    { path: "/admin/edition/:id", element: <EditionList /> },
    { path: "/admin/edition/constestant/:id", element: <AdminContestantForEdition /> },
    { path: "/admin/edition/result/:id", element: <AdminEditionResult /> },
    { path: "/admin/faqs", element: <Faqs /> },
    { path: "/admin/reports", element: <Reports /> },
    { path: "/admin/setting", element: <Setting /> },
    { path: "/admin/contestants", element: <AdminContestantsList /> },
    { path: "/admin/contestant/vote/:id", element: <AdminContestantVotes /> },
    { path: "/admin/voting", element: <AdminVotingList /> },
    { path: "/admin/withdrawals", element: <AdminWithDrawlsList /> },

    // Add more admin routes as needed
];

export default AdminRoutes;
