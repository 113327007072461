import React, { useState } from 'react';
import { Table, Modal, Form, Input, Button, Upload } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate, useParams, Link } from "react-router-dom";
import useFetch from '../../Helper/useFetch';
import { UploadOutlined } from '@ant-design/icons';

const Reports = () => {
    const { id } = useParams()
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}report`, [], true);
    const [postLoading, setPostLoading] = useState(false);
    const api = `${apiUrl}report`;
    const [form] = Form.useForm();
    const getImgUrl = (url) => {

        if (url?.startsWith('/')) {
            url = url?.substring(1)
            return apiUrl + url
        }
        return apiUrl + url

    }
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Screenshot',
            dataIndex: 'image',
            key: 'image',
            render: data => (
                data ? (
                    <img className="icon-image-column" crossOrigin="anonymous" src={getImgUrl(data)} />
                ) : "NA"
            )
        },
        {
            title: 'Issue',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onFinish = async (values) => {
        const formData = new FormData();
        try {
            setPostLoading(true)
            formData.append("subject", values.subject)
            formData.append("description", values.description)
            formData.append('image', values?.image?.file);


            // const method = isEdit ? 'PUT' : 'POST';

            // const authToken = process.env.TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA';
            const authToken = localStorage.getItem("Voice-Token")
            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    // 'Content-Type': 'application/json', // Step 4: Set Content-Type
                },
                body: formData, // Step 3: Convert data to JSON string
            });

            if (response.ok) {
                const jsonData = await response.json();
                form.resetFields();
                console.log('API response:', jsonData);



            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error in onFinish:', error);
        } finally {
            setPostLoading(false)
        }
    };


    return (
        <div>
            <Nav className='addEventDiv'>
                <Nav.Link to="/admin/events/add" className='themeBtn' onClick={showModal}>
                    Send a Complaint
                </Nav.Link>
            </Nav>
            <Table columns={columns} dataSource={data?.data} />

            {/* Modal for Complaint Form */}
            <Modal
                title="Send a Complaint"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form name="complaintForm" onFinish={onFinish} form={form}>
                    <Form.Item
                        name="subject"
                        label="Subject"
                        rules={[{ required: true, message: 'Please enter the subject!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please enter a description!' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="Image" name="image">

                        <Upload
                            beforeUpload={() => false}
                            accept="image/*"
                            maxCount={1}
                            listType="picture"
                        >
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={postLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Reports;
