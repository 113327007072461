import React, { useEffect, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import './AdminLayout.css';
import { useNavigate } from 'react-router-dom';
import Constants from '../Components/UiKit/Constants';
import { DashboardOutlined, MessageOutlined } from '@ant-design/icons';
import Accordion from 'react-bootstrap/Accordion';

const AdminSideMenu = () => {

    const [activeLink, setActiveLink] = useState('AdminDashboard');

    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(null);
    const [activeItem, setActiveItem] = useState("overview"); // Initialize with "overview"

    const handleAccordionItemClick = (eventKey, item) => {
        setActiveKey(eventKey === activeKey ? null : eventKey);
        setActiveItem(item);
    };

    useEffect(() => {
        const storedActiveLink = sessionStorage.getItem('activeLink');
        if (storedActiveLink) {
            setActiveLink(storedActiveLink);
        }
    }, []);

    return (
        <div className="admin-side-menu">
            <div className='admin-logo'>
                <img src={Constants.LOGO} alt="Logo" className='logo' />
            </div>

            <div className='menuBox'>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><img src={Constants.ADMINICON1} /> Dashboard</Accordion.Header>
                        <Accordion.Body>
                            <NavDropdown.Item className={activeItem === "overview" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'overview');
                                    navigate("/admin/overview");
                                }}>Overview</NavDropdown.Item>
                            <NavDropdown.Item className={activeItem === "voting" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'voting');
                                    navigate("/admin/voting");
                                }}>Voting</NavDropdown.Item>
                            <NavDropdown.Item className={activeItem === "withdrawals" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'withdrawals');
                                    navigate("/admin/withdrawals");
                                }}>Withdrawals</NavDropdown.Item>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><img src={Constants.ADMINICON2} /> Manage Voting</Accordion.Header>
                        <Accordion.Body>
                            <NavDropdown.Item className={activeItem === "events" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'events');
                                    navigate("/admin/events");
                                }}>Events</NavDropdown.Item>
                            <NavDropdown.Item className={activeItem === "category" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'category');
                                    navigate("/admin/category");
                                }}>Category</NavDropdown.Item>
                            <NavDropdown.Item className={activeItem === "edition" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'edition');
                                    navigate("/admin/edition");
                                }}>Edition</NavDropdown.Item>
                            <NavDropdown.Item className={activeItem === "contestants" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'contestants');
                                    navigate("/admin/contestants");
                                }}>Contestants</NavDropdown.Item>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Nav.Link className={activeItem === "faqs" ? "active" : ""} onClick={() => {
                    setActiveItem("faqs");
                    navigate("/admin/faqs");
                }}><img src={Constants.ADMINICON4} /> FAQ’s</Nav.Link>
                <Nav.Link className={activeItem === "reports" ? "active" : ""} onClick={() => {
                    setActiveItem("reports");
                    navigate("/admin/reports");
                }}><img src={Constants.ADMINICON6} /> Report</Nav.Link>
                <Nav.Link className={activeItem === "setting" ? "active" : ""} onClick={() => {
                    setActiveItem("setting");
                    navigate("/admin/setting");
                }}><img src={Constants.ADMINICON7} /> Setting</Nav.Link>

            </div>
        </div>
    );
};

export default AdminSideMenu;
