import React, { createContext, useState, useContext } from 'react';

// Create a context with a default value
const UserContext = createContext({
    isLogging: false,
    setLogging: () => { },
});

// Create a context provider component
export const UserContextProvider = ({ children }) => {
    const [isLogging, setIsLogging] = useState(false);

    const setLogging = (value) => {
        setIsLogging(value);
    };

    return (
        <UserContext.Provider value={{ isLogging, setLogging }}>
            {children}
        </UserContext.Provider>
    );
};

// Create a custom hook to access the context
export const useUserContext = () => {
    return useContext(UserContext);
};
