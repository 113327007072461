import React, { useEffect, useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import '../AdminPanel/AdminLayout.css';
import { useNavigate } from 'react-router-dom';
import Constants from '../Components/UiKit/Constants';
import { DashboardOutlined, MessageOutlined } from '@ant-design/icons';
import Accordion from 'react-bootstrap/Accordion';

const SuperAdminSideMenu = () => {

    const [activeLink, setActiveLink] = useState('AdminDashboard');

    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(null);
    const [activeItem, setActiveItem] = useState("overview"); // Initialize with "overview"

    const handleAccordionItemClick = (eventKey, item) => {
        setActiveKey(eventKey === activeKey ? null : eventKey);
        setActiveItem(item);
    };

    useEffect(() => {
        const storedActiveLink = sessionStorage.getItem('activeLink');
        if (storedActiveLink) {
            setActiveLink(storedActiveLink);
        }
    }, []);

    return (
        <div className="admin-side-menu">
            <div className='admin-logo'>
                <img src={Constants.LOGO} alt="Logo" className='logo' />
            </div>

            <div className='menuBox'>
                <Accordion>

                    <Accordion.Item eventKey="0">
                        <Accordion.Header><img src={Constants.ADMINICON2} /> Manage Voting</Accordion.Header>
                        <Accordion.Body>
                            <NavDropdown.Item className={activeItem === "events" ? "active" : ""}
                                onClick={() => {
                                    handleAccordionItemClick('1', 'events');
                                    navigate("/superadmin/events");
                                }}>Events</NavDropdown.Item>

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Nav.Link className={activeItem === "commision" ? "active" : ""} onClick={() => {
                    setActiveItem("commision");
                    navigate("/superadmin/commision");
                }}><img src={Constants.ADMINICON7} /> Commision Rates
                </Nav.Link>
                <Nav.Link className={activeItem === "reports" ? "active" : ""} onClick={() => {
                    setActiveItem("reports");
                    navigate("/superadmin/reports");
                }}><img src={Constants.ADMINICON6} /> Report</Nav.Link>
            </div>
        </div>
    );
};

export default SuperAdminSideMenu;
