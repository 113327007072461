import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate } from "react-router-dom";

export const useFetch = (path, initialState, immediate = true) => {


    const [data, setData] = useState(null);

    const [response, setResponse] = useState(initialState);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const history = useNavigate()


    const getData = async (url) => {
        setIsLoading(true)
        try {

            const token = localStorage.getItem("Voice-Token")
            // const token = process.env.TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA'
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const response = await fetch(url, {
                headers: headers
            });
            const jsonData = await response.json();
            setData(jsonData);
            // Commented for deveplopment
            // if (!response.ok) {
            //     // toast.error("Session expired,please login again");
            //     history("/")
            //     throw new Error(toast.error(jsonData.message ?? "Session Expired"));
            // }

            // const jsonData = await response.json();
            setData(jsonData);
            var result
            if (response.data) {
                result = response.data
            } else {
                result = response.response
            }
            return result
        } catch (error) {
            setErrorMsg(error.message);
        }
        finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (immediate) {
            getData(path)
        }
    }, [])

    return [data, isLoading, getData, errorMsg, setResponse,];
};

export default useFetch
