import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useFetch from '../../Helper/useFetch';

const { Option } = Select;

const AdminEditionContestant = (props) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const { id } = useParams();
    const [postLoading, setPostLoading] = useState(false);
    console.log({ props })

    let api;
    let isEdit = false;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}event/${id}`, {}, false);
    const [categoryData, isCLoading, getCategoryData] = useFetch(`${apiUrl}category/event/${props.eventId}`, {}, true);
    const [contestantData, isCOLoading, getContestantData] = useFetch(`${apiUrl}user/contestant/${props.contestantId}`, {}, true);


    if (props.contestantId) {
        api = `${apiUrl}user/contestant/${props.contestantId}`;
        isEdit = true;
    } else {
        api = `${apiUrl}user/contestant`;
    }

    const [file, setFile] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            getData(`${apiUrl}event/${id}`);
        }
    }, [id]);

    const response = data?.data;
    useEffect(() => {
        if (isEdit && response) {
            form.setFieldsValue({
                eventName: response.name,
                eventDescription: response.description,
            });
            setFile(response.image);
        }
    }, [isEdit, response]);

    const onFinish = async (values) => {
        try {
            setPostLoading(true);
            const formData = new FormData();
            formData.append('eventId', props.eventId);
            formData.append('editionId', props.editionId);
            formData.append('categoryId', values.categoryId);

            formData.append('files', values?.image?.file);
            // formData.append('files', file);
            formData.append('description', values.eventDescription);

            formData.append('firstname', values.contestantName.split(' ')[0]);
            formData.append('lastname', values.contestantName.split(' ')[1]);

            // formData.append('price', values.price);

            const method = isEdit ? 'PUT' : 'POST';

            const authToken = localStorage.getItem("Voice-Token");
            const response = await fetch(api, {
                method,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
                body: formData,
            });

            if (response.ok) {
                const jsonData = await response.json();
                console.log('API response:', jsonData);
                if (!isEdit) {
                    form.resetFields();
                }
                props.onCloseModal();
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error in onFinish:', error);
        } finally {
            setPostLoading(false);
        }
    };

    const handleChange = (e) => {
        setFile(e.target.files[0]);
    };

    const customRequest = ({ file, onSuccess, onError }) => {
        // You can implement your custom logic for uploading the file here.
    };
    useEffect(() => {
        if (props.contestantId && contestantData && contestantData.data) {
            const data = contestantData.data;

            form.setFieldsValue({
                eventId: props.eventId,
                categoryId: data.categoryId,
                contestantName: `${data.firstname} ${data.lastname}`,
                stageName: data.stageName || '',
                state: data.state || '',
                // Add other form fields based on your data structure

                // For the image field, set the file state
                // files: data.image ? [data.image] : null,
            });
        }
    }, [props.contestantId, contestantData, form]);


    console.log(contestantData?.data)

    return (
        <Form
            form={form}
            name="event_form"
            onFinish={onFinish}
        >

            <Form.Item
                label="Select Category"
                name="categoryId"
                rules={[{ required: true, message: 'Please enter the category name!' }]}
            >
                <Select placeholder="Select an event">
                    {categoryData?.data?.map((category) => (
                        <Option key={category.id} value={category.id}>
                            {category.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="Contestant Name"
                name="contestantName"
                rules={[{ required: true, message: 'Please enter the contestant name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Stage Name"
                name="stageName"
                rules={[{ required: true, message: 'Please enter stage name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="State"
                name="state"
                rules={[{ required: true, message: 'Please enter state name!' }]}
            >
                <Input />
            </Form.Item>
            {/* 
            <Form.Item
                label="Contestant Profile"
            >
                <Input type="file" placeholder="Contestant Logo" onChange={handleChange} />
            </Form.Item> */}
            <Form.Item label="Contestant Profile" name="image">
                <Upload
                    beforeUpload={() => false}
                    accept="image/*"
                    maxCount={1}
                    listType="picture"
                >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            </Form.Item>

            {/* <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: 'Please enter the price!' }]}
            >
                <Input />
            </Form.Item> */}

            <Form.Item
                label="Event Description"
                name="eventDescription"
                rules={[
                    {
                        required: true,
                        message: 'Please enter the event description!',
                    },
                ]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit" className='openFormsBtn' disabled={postLoading}>
                    {isEdit ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AdminEditionContestant;
