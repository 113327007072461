
import React from 'react';
import { Route, Routes, Switch } from 'react-router-dom';
import { Suspense } from "react";

import { Outlet } from "react-router-dom";

import '../AdminPanel/AdminLayout.css'

import { jwtDecode } from "jwt-decode";

import SuperAdminSideMenu from "./SuperAdminSideMenu";
import { TokenProvider } from '../AdminPanel/TokenProvider';
import TopPanel from '../AdminPanel/TopPanel/TopPanel';
import Loader from '../Components/UiKit/Loader';

function SuperAdminPanel() {
    const token = localStorage.getItem("Voice-Token");
    const decodedToken = jwtDecode(token);

    return (

        <TokenProvider token={decodedToken}>
            <div className="admin-layout">
                <SuperAdminSideMenu />
                <main className="admin-content">
                    <Suspense fallback={<Loader />}>
                        <TopPanel />
                        <Outlet />
                    </Suspense>
                </main>
            </div>
        </TokenProvider>
    );
}

export default SuperAdminPanel;
