import React, { useState } from 'react';

const ChangePasswordForm = ({ visible, onClose }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = () => {
        // Handle password change logic here
        if (newPassword === confirmPassword) {
            // Passwords match, perform the change
            // You can send a request to your server here
            console.log('Password change requested:', {
                oldPassword,
                newPassword,
            });
            onClose();
        } else {
            // Passwords do not match, show an error message or handle as needed
        }
    };

    return (
        <div className={`password-change-modal ${visible ? 'show' : ''}`}>
            <div className="modal-content">
                <h2>Change Password</h2>
                <input
                    type="password"
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button onClick={handleSubmit}>Submit</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
