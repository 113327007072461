import React, { useEffect } from 'react';
import { Form, Input, Button, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useFetch from '../../Helper/useFetch';
import { useState } from 'react';

const AdminCategory = (props) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const { id } = useParams();
    let api;
    const [postLoading, setPostLoading] = useState(false);
    let isEdit = false;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}event/${id}`, {}, false);

    if (id && !props.fromList) {
        api = `${apiUrl}admin/event/${id}`;
        isEdit = true;
    }
    else if (id && props.fromList) {

        api = `${apiUrl}category`;
    } else {
        api = `${apiUrl}category`;
    }

    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            getData(`${apiUrl}event/${id}`);
        }
    }, [id]);

    const response = data?.data;

    useEffect(() => {
        if (isEdit && response) {
            form.setFieldsValue({
                eventName: response.name,
            });
        }
    }, [isEdit, response]);

    const onFinish = async (values) => {
        const formData = new FormData();
        try {
            setPostLoading(true)
            formData.append("name", values.categoryName)
            formData.append("eventId", props.eventId)
            formData.append('image', values?.image?.file);
            formData.append("description", values.categoryDescription)

            const requestData = {
                name: values.categoryName,
                eventId: props.eventId,
            };

            const method = isEdit ? 'PUT' : 'POST';

            // const authToken = process.env.TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA';
            const authToken = localStorage.getItem("Voice-Token")
            const response = await fetch(api, {
                method,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    // 'Content-Type': 'application/json', // Step 4: Set Content-Type
                },
                body: formData, // Step 3: Convert data to JSON string
            });

            if (response.ok) {
                const jsonData = await response.json();
                console.log('API response:', jsonData);
                if (!isEdit) {
                    form.resetFields();
                }
                props.onCloseModal();
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error in onFinish:', error);
        } finally {
            setPostLoading(false)
        }
    };

    return (
        <Form
            form={form}
            name="event_form"
            onFinish={onFinish}
        // labelCol={{ span: 4 }}
        // wrapperCol={{ span: 16 }}
        >
            {props.eventName &&
                <Form.Item
                    label="Event Name" // Change the label to "Event Name"
                >
                    <Input
                        disabled
                        value={props?.eventName}
                        style={{ fontWeight: 'bold' }} // Apply bold style to the field
                    />            </Form.Item>}

            <Form.Item label="Image" name="image">

                <Upload
                    beforeUpload={() => false}
                    accept="image/*"
                    maxCount={1}
                    listType="picture"
                >
                    <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
            </Form.Item>
            <Form.Item
                label="Category Name"
                name="categoryName"
                rules={[{ required: true, message: 'Please enter the category name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Description" // Label for the input field for the description
                name="categoryDescription"
            >
                <Input.TextArea rows={4} placeholder="Enter the category description" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={postLoading} className='openFormsBtn'>
                    {postLoading ? <Spin size="small" /> : (isEdit ? 'Update' : 'Submit')}
                </Button>
            </Form.Item>

        </Form>
    );
};

export default AdminCategory;
