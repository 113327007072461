import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import useFetch from "../../Helper/useFetch";
import { getImgUrl } from '../../Helper/getImgUrl';
import AdminCategory from './AdminCategory';
import Constants from '../../Components/UiKit/Constants';

const AdminCategoryList = () => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate();
    const [data, isLoading, error] = useFetch(`${apiUrl}admin/event`, [], true);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedEventName, setSelectedEventName] = useState(null); // Step 1: State to store selected event ID

    const handleOpenModal = (eventId, eventName) => { // Step 2: Update state with event ID
        setSelectedEventId(eventId);
        setSelectedEventName(eventName)
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setSelectedEventId(null);
        setSelectedEventName(null);// Clear the selected event ID when closing the modal
        setIsModalVisible(false);
    };

    return (
        <>
            <div className="eventList">
                <div className='ongoingEventSection sectionPadding'>
                    <Container>
                        <Row>
                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-4'>
                                    <div className='eventBox'>
                                        {/* <div className='contestantBox'>Paid</div> */}
                                        {/* Step 2: Pass the event ID to handleOpenModal */}
                                        {/* <button type="button" className="themeBtn voteBtn" onClick={() => handleOpenModal(i.id)}>
                                            Add Category
                                        </button> */}
                                        {/* <button type="button" className="eventEditBtn" onClick={() => navigate(`/admin/categories/${i.id}`)}>
                                            <img src={Constants.EDITICON} />
                                        </button> */}
                                        <img src={getImgUrl(i.image)} alt="event" crossOrigin="anonymous" className='adminEventImg' />
                                        <div className='eventDtls'>
                                            <div className='dateBox'>
                                                <span>{new Date(i.fromTime).toLocaleString('default', { month: 'short' })}</span>
                                                {new Date(i.fromTime).getDate()}
                                            </div>
                                            <div>
                                                <h5>{i.name}</h5>
                                                <p>{i.description}</p>
                                            </div>
                                        </div>
                                        <div className='categoryAddButton'>
                                            <button className='openFormsBtn' onClick={() => handleOpenModal(i.id, i.name)}>Add Category</button>
                                            <button className='openFormsBtn' onClick={() => navigate(`/admin/categories/${i.id}`)}>View All</button>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </div>

            <Modal
                title="Add Category"
                visible={isModalVisible}
                onCancel={handleCloseModal}
                footer={[
                    <Button key="back" onClick={handleCloseModal}>
                        Cancel
                    </Button>,
                    // <Button key="submit" type="primary" onClick={handleCloseModal}>
                    //     Save
                    // </Button>,
                ]}
            >
                {/* Step 3: Pass the selected event ID as a prop to AdminCategory */}
                <AdminCategory eventId={selectedEventId} eventName={selectedEventName} onCloseModal={handleCloseModal} />
            </Modal>
        </>
    );
};

export default AdminCategoryList;
