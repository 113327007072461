import "./Result.css"
import { Table } from "antd"

const Result = () => {

    const dataColumns = [
        {
            title: 'Contestant',
            dataIndex: 'service',

        },
        {
            title: 'Votes',
            dataIndex: 'service',

        },
        {
            title: '%',
            dataIndex: 'service',

        },
    ]

    return (

        <>
            <div className="resultTop">


                <div className="resultHeader">
                    <p className="resultHeaderText">
                        Results for Best Outside Hitter Men
                    </p>
                    {/* "https://c.animaapp.com/CSoAjsAi/img/rectangle-2.png" */}

                </div>
            </div>
            {/* <div className="resultTable"> */}
            <Table
                columns={dataColumns}
                className="resultTable">
            </Table>

            {/* </div> */}

        </>




    )





}

export default Result