import AdminLayout from "./AdminLayout";
import React from 'react';
import { Route, Routes, Switch } from 'react-router-dom';
import { Suspense } from "react";
import Loader from "../Components/UiKit/Loader";
import { Outlet } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import "./AdminLayout.css"
import TopPanel from "./TopPanel/TopPanel";
import { jwtDecode } from "jwt-decode";
import { TokenProvider } from "./TokenProvider";

function AdminPanel() {
    const token = localStorage.getItem("Voice-Token");
    const decodedToken = jwtDecode(token);

    return (
        <TokenProvider token={decodedToken}>
            <div className="admin-layout">
                <AdminSideMenu />
                <main className="admin-content">
                    <Suspense fallback={<Loader />}>
                        <TopPanel />
                        <Outlet />
                    </Suspense>
                </main>
            </div>
        </TokenProvider>
    );
}

export default AdminPanel;
