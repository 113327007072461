import { Form, Input, Button, Col, Row } from "antd";
import "./ContactUs.css"

const ContactUs = () => {

    const onFinish = (values) => {
        console.log(values)
    }

    return (
        <>
            <div className="contactUs">
                <div className="contactUsTop">
                    <p className="topContent">
                        Contact Us
                    </p>
                </div>



            </div>
            <div className="contactUsForm pt-5 pb-5">
                <div className="mb-5">
                    <h1>Drop a message</h1>
                    <p>Feel free to contact us and we will get back to you as soon as we can.</p>
                </div>
                <div className="container">
                    <div className="contactUsFormWrap">
                        <Form name="contactForm" className="userLoginInput" onFinish={onFinish}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"

                                        className="innerInputBox"
                                        rules={[{ required: true, message: 'Please enter your name' }]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="email"

                                        className="innerInputBox"
                                        rules={[
                                            { required: true, message: 'Please enter your email' },
                                            { type: 'email', message: 'Please enter a valid email address' },
                                        ]}
                                    >
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="subject"

                                        className="innerInputBox"
                                        rules={[{ required: true, message: 'Please enter the subject' }]}
                                    >
                                        <Input placeholder="Subject" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="message"

                                        className="innerInputBox"
                                        rules={[{ required: true, message: 'Please enter your message' }]}
                                    >
                                        <Input placeholder="Message" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="signInBtn">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ContactUs;