import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { Modal, Button } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import useFetch from '../../Helper/useFetch';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Constants from '../../Components/UiKit/Constants';
import AdminEdition from '../AdminEdition/AdminEdition';
import AdminEditionContestant from '../AdminEdition/AdminEditionContestant';


const AdminContestantsList = () => {
    // const [data, setData] = useState([]);
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate()

    const [data, isPLoading, getData] = useFetch(`${apiUrl}/edition`, [], true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [isContestantModalVisible, setIsConstestantModalVisible] = useState(false);
    // const [isModalVisible, setIsModalVisible] = useState(false);

    const [selectedEditionId, setSelectedEditionId] = useState(null);  // Step 1: State to store selected event ID

    const handleOpenModal = (eventId) => { // Step 2: Update state with event ID
        setSelectedEventId(eventId);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setSelectedEventId(null); // Clear the selected event ID when closing the modal
        setIsModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleContestantModal = (eventId, editionId) => {
        setSelectedEventId(eventId);
        setSelectedEditionId(editionId)
        setIsConstestantModalVisible(true)

    }
    const columns = [

        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'Event Date',
            dataIndex: 'eventDate',
            key: 'eventDate',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
    ];

    const getImgUrl = (url) => {

        if (url?.startsWith('/')) {
            url = url?.substring(1)
            return apiUrl + url
        }
        return apiUrl + url

    }
    const handleContestantCloseModal = (eventId, editionId) => {

        setIsConstestantModalVisible(false)
    }

    return (
        <>


            {/* eventBanner */}

            <div className='adminEventSearch'>
                <div>
                    <img src={Constants.EVENTSEARCHICON} alt="Search Icon" />
                    <input type="text" placeholder="Search" />
                </div>
                <div>
                    {/* <div className='addEventDiv'>
                        <Nav className='addEventDiv'>
                            <Nav.Link to="/admin/events/add" onClick={showModal} className='themeBtn'>
                                Add Edition
                            </Nav.Link>
                        </Nav>
                    </div> */}
                </div>
            </div>

            <div className="eventList">
                <div className='ongoingEventSection'>
                    <Container>
                        <Row>
                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-4'>
                                    <div className='eventBox'>
                                        <div className='contestantBox'>Paid</div>
                                        <button type="button" className="eventEditBtn" style={{ right: '50px' }}> <img src={Constants.DELETEICON} /> </button>
                                        {/* <button type="button" className="eventEditBtn" onClick={() => navigate(`/admin/edition/${i.id}`)}> <img src={Constants.EDITICON} /> </button> */}
                                        <img src={getImgUrl(i.image)} alt="event" crossOrigin="anonymous" className='adminEventImg' />

                                        <div className='eventNameBox'>
                                            <div className='adminEventName'>
                                                <h4>{i.name}</h4>
                                            </div>

                                        </div>

                                        <div className='adminEventDtls'>
                                            {/* <div className='dateBox'>
                                                <span>{new Date(i.fromTime).toLocaleString('default', { month: 'short' })}</span>
                                                {new Date(i.fromTime).getDate()}
                                            </div> */}
                                            <div>
                                                <p>Short Code :<b>54</b></p>
                                            </div>


                                        </div>

                                        <div className='adminContestantButtons'> <button className='openFormsBtn' style={{ "fontSize": "11px" }} onClick={() => navigate(`/admin/edition/constestant/${i.id}`)}>View Contestants</button>
                                            <button className='openFormsBtn' style={{ "fontSize": "11px", "marginLeft": "10px" }} onClick={() => navigate(`/admin/contestant/vote/${i.id}`, { state: { eventId: i.eventId } })}>Votes</button>
                                            <button className='openFormsBtn' style={{ "fontSize": "11px", "marginLeft": "10px" }} onClick={() => handleContestantModal(i.eventId, i.id)} >Add Contestant</button></div>

                                        {/* <button className='openFormsBtn' onClick={() => handleOpenModal(i.id)}>Add Edition</button> */}
                                        {/* <button className='openFormsBtn'>Open Forms</button> */}
                                        {/* <button className='themBtn'>Activate</button> */}
                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </Container>

                </div>
            </div >
            <Modal
                title="Add Edition"
                visible={isModalVisible}
                onCancel={handleCloseModal}
                footer={[
                    <Button key="back" onClick={handleCloseModal}>
                        Cancel
                    </Button>,
                ]}
            >
                {/* Render the AdminEvents component in the modal */}
                <AdminEdition eventId={selectedEventId} />
            </Modal>
            <Modal
                title="Add Contestant"
                visible={isContestantModalVisible}
                onCancel={handleContestantCloseModal}
                footer={[
                    <Button key="back" onClick={handleContestantCloseModal}>
                        Cancel
                    </Button>,
                ]}
            >
                {/* Render the AdminEdition component in the modal */}
                <AdminEditionContestant eventId={selectedEventId} editionId={selectedEditionId} onCloseModal={handleContestantCloseModal} />
            </Modal>

        </>);
};

export default AdminContestantsList;
