import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Space, Table, Tag } from 'antd';
import Constants from '../Components/UiKit/Constants';
import { Pie } from "@ant-design/charts";
import useFetch from '../Helper/useFetch';

const SuperAdminOverView = (props) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}dashboard`, [], true);
    const result = data?.data

    const columns = [
        {
            title: 'Event ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'Category',
            dataIndex: 'categoryId',
            key: 'categoryId',
        },
        {
            title: 'Description',
            dataIndex: 'description ',
            key: 'description',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate ',
            key: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
    ];
    const pieChartData = [
        {
            type: "voting",
            value: 40
        },
        {
            type: "edition",
            value: 25
        },
        {
            type: "withdrawl",
            value: 22
        },
        {
            type: "votes",
            value: 22
        },

    ];

    const config = {
        appendPadding: 10,
        data: pieChartData,
        angleField: "value",
        colorField: "type",
        radius: 1,
        innerRadius: 0.5,
        color: ['#ffe9d4', '#ffd1a0', '#ffb66e', '#ffa33d'],
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}",
            style: {
                textAlign: "center",
                fontSize: 14
            }
        },
        interactions: [{ type: "element-selected" }, { type: "element-active" }],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                formatter: function formatter() {
                    return `total\n134`;
                }
            }
        }
    };


    return (
        <>
            <div className='overViewCountWrapper'>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON1} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Income</h4>
                            </div>
                            <div>
                                74000
                                {/* <h4 className='countNo'>72000</h4> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON1} alt='Over View' />
                            </div>
                            <div>
                                <h4>Monthly Income</h4>
                            </div>
                            <div>
                                74000
                                {/* <h4 className='countNo'>72000</h4> */}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON2} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Events</h4>
                            </div>
                            27
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON3} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Organizer</h4>
                            </div>
                            18
                        </div>
                    </Col>

                </Row>




            </div>
        </>
    )
};

export default SuperAdminOverView;
