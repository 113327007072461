import React, { useState } from 'react';
import useFetch from "../../Helper/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import useDeleteApi from '../../Helper/useDeleteApi';
import { Table, Button, Space, Modal, Form, Input } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import useDelete from '../../Helper/useDelete';
import NewPostApi from '../../Helper/NewPostApi';
import { useLocation } from "react-router-dom";
import Constants from '../../Components/UiKit/Constants';
import Container from 'react-bootstrap/Container';
import { Row, Col, Nav } from 'react-bootstrap';
import PathConstants from '../../Helper/Routes/PathConstants';
import AdminEditionContestant from './AdminEditionContestant';

import 'react-confirm-alert/src/react-confirm-alert.css';


const AdminContestantForEdition = ({ eventId }) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate();
    const { id } = useParams();
    const [categoryName, setCategoryName] = useState("")
    const [recordId, setRecordId] = useState("")

    const [data, isPLoading, getData] = useFetch(`${apiUrl}edition/${id}`, [], true);
    const categoriesToUpdate = NewPostApi(`${apiUrl}edition/${recordId}`)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedContestantId, setSelectedContestantId] = useState(null);
    const deleteItem = useDelete();
    const [editEditionName, setEditEditionName] = useState('');
    const { categoriesLoading, categoryError, sendDeleteRequest } = useDeleteApi();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Contestant First Name',
            dataIndex: 'firstname',
            key: 'firstname',
        },
        {
            title: 'Contestant Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: data => (
                data ? data : "-"
            )
        },
        {
            title: 'Code',
            dataIndex: 'shortCode',
            key: 'shortCode',
        },
        {
            title: 'Profile',
            dataIndex: 'image',
            key: 'image',
            render: data => (
                data ? (
                    <img className="icon-image-column" crossOrigin="anonymous" src={`${apiUrl}${data}`} />
                ) : "NA"
            )
        },

        // {
        //     title: 'Actions',
        //     key: 'actions',
        //     render: (text, record) => (
        //         <Space size="middle">
        //             <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
        //             <Button type="danger" onClick={() => handleDelete(record.id)}>Delete</Button>
        //         </Space>
        //     ),
        // },
    ];

    const handleEdit = (record) => {
        setRecordId(record.id)
        setEditEditionName(record.name);
        setIsModalVisible(true);
    };

    const handleDelete = (params) => {
        console.log({ params })
        // confirmAlert({
        //     title: "Confirm delete",
        //     message: "Are you sure to delete ?",
        //     buttons: [
        //         {
        //             label: "Yes",
        //             onClick: () => {
        // deleteItem(`${apiUrl}category/${params}`, getData, `${apiUrl}category/${params}`)

        // }
        //         },
        //         {
        //             label: "No",
        //             onClick: () => console.info(" cancelled."),
        //         },
        //     ],
        // });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleFormSubmit = async (values) => {
        try {
            await categoriesToUpdate.addOrUpdateData({ name: values.categoryName, eventId: id }, true);
            // Make an API request to update the category name using values.categoryName
            // After successful update, close the modal and update the data source or take appropriate action

            setIsModalVisible(false);
        } catch (error) {
            // Handle the error here, e.g., log it or show an error message to the user
            console.error(error);
        }
    };




    // return (
    //     <div>
    //         <Table
    //             columns={columns}
    //             dataSource={data?.data?.contestant}
    //         />
    //         <Modal
    //             title="Edit Category"
    //             visible={isModalVisible}
    //             onCancel={handleCancel}
    //             footer={null}
    //         >
    //             <Form
    //                 onFinish={handleFormSubmit}
    //                 initialValues={{ editionName: editEditionName }}
    //             >
    //                 <Form.Item
    //                     label="Edition Name"
    //                     name="editionName"
    //                     rules={[{ required: true, message: 'Please enter a edition name' }]}
    //                 >
    //                     <Input />
    //                 </Form.Item>
    //                 <Form.Item>
    //                     <Button type="primary" htmlType="submit">
    //                         Save
    //                     </Button>
    //                 </Form.Item>
    //             </Form>
    //         </Modal>
    //     </div>
    // );
    const handleContestantModal = (eventId, contestantId) => {
        setSelectedEventId(eventId)
        setIsModalVisible(true);
        setSelectedContestantId(contestantId)
    };


    const contestantDelete = (params) => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteItem(`${apiUrl}user/contestant/${params}`, getData, `${apiUrl}edition/${id}`),
                },
                {
                    label: 'No',
                    onClick: () => { }, // Do nothing on "No" click
                },
            ],
        });
    };

    return (
        <div className="eventList">
            <div className='ongoingEventSection sectionPadding'>

                <Container>
                    <Row>



                        {data?.data?.contestant.map(i => (
                            <Col key={i.id} className='col-12 col-sm-4 col-md-3'>
                                <div className='eventBox'>


                                    <div className='contestantDtls'>
                                        <button type="button" className="eventEditBtn" onClick={() => handleContestantModal(i.eventId, i.id)}> <img src={Constants.EDITICON} /> </button>
                                        <button type="button" className="eventEditBtn" style={{ right: '50px' }} onClick={() => contestantDelete(i.id)}> <img src={Constants.DELETEICON} /> </button>
                                        <div className="contestantImage">
                                            {i.image ? <img src={`${apiUrl}${i.image}`} style={{ borderRadius: "10px" }} alt="event" crossOrigin="anonymous" /> : <img
                                                src="https://i.pinimg.com/736x/fa/60/51/fa6051d72b821cb48a8cc71d3481dfef.jpg"
                                                style={{ borderRadius: "10px" }}
                                                alt="event"
                                                crossOrigin="anonymous"
                                            />}
                                        </div>


                                        <h5 className="contestantName">{i.firstname} {i.lastname}</h5>
                                        <h6 className="contestantName">{i.category?.name}</h6>
                                        <p className="contestantState">Short Code {i.shortCode ? i.shortCode : "NA"}</p>
                                        <button type="button" className="contestantVote"

                                        >Vote</button>


                                    </div>
                                </div>
                            </Col>
                        ))}




                    </Row>
                </Container>
                <Modal
                    title="Contestant Details"
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                >
                    {/* Render the content of the modal, you can use the AdminEditionContestant component */}
                    <AdminEditionContestant
                        eventId={selectedEventId}
                        editionId={id}
                        isUpdate={true}
                        contestantId={selectedContestantId}
                    // Pass other necessary props based on your requirements
                    />
                </Modal>

            </div >
        </div >
    )
};

export default AdminContestantForEdition;
