export const getImgUrl = (url) => {
    const apiUrl = process.env.REACT_APP_VOICE_API

    if (url?.startsWith('/')) {
        url = url?.substring(1)
        return apiUrl + url
    }
    return apiUrl + url

}
