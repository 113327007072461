import React, { useState } from 'react';
import { Col, Row, Card, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import "./Setting.css";
import Constants from '../../Components/UiKit/Constants';
import BankDetailsForm from './BankDetailsForm';
import ChangePasswordForm from './ChangePasswordForm';
import { useNavigate } from 'react-router-dom';

const Setting = () => {
    const [isBankDetailsModalVisible, setIsBankDetailsModalVisible] = useState(false);
    const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
    const navigate = useNavigate()

    const showBankDetailsModal = () => {
        setIsBankDetailsModalVisible(true);
    };

    const showChangePasswordModal = () => {
        setIsChangePasswordModalVisible(true);
    };

    const handleBankDetailsSubmit = (values) => {
        // Handle bank details form submission here
        console.log('Bank details submitted:', values);
        setIsBankDetailsModalVisible(false); // Close the bank details modal
    };

    const handlePasswordCancel = () => {
        setIsChangePasswordModalVisible(false);
    };

    const handleAccountCancel = () => {
        setIsBankDetailsModalVisible(false);
    }

    const handleLogout = () => {
        localStorage.removeItem("Voice-Token")
        navigate("/")
    }

    return (
        <div className='setting'>
            <Container >
                <Row className='align-items-center' style={{ color: "white" }}>
                    <Col className='col-12 col-sm-6'>
                        <div className='settingLeftCol'>
                            <div className="settingInfo">
                                <h4>INFORMATION</h4>
                            </div>
                            <div className="settingProfile">
                                <img src={Constants.USERPIC} />
                                <p className='mb-4'>Name: <span style={{ color: "#fff" }}>John Williams</span></p>
                                <p className='mb-4'>Organization: <span style={{ color: "#fff" }}>K&K</span></p>
                                <p className='mb-4'>Email: <span style={{ color: "#fff" }}>ka@hgmail.com</span></p>
                                <button className='themeBtn' onClick={showChangePasswordModal}>Change Password</button>
                                <button className='themeBtn' onClick={handleLogout}>Logout</button>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-sm-6'>
                        <div className='settigRightCol'>
                            <div className='paymentSetting'>
                                <h4 className="h4-custom">Wallet</h4>
                                <h6 >Kindly provide us with an MTN wallet / Merchant ID for 0% withdrawal fee</h6>
                                <Card className='cardInfo'>
                                    <img src={Constants.WALLETLOGO} />
                                    <p>Test - fixehek493@stypedia.com HDFI655623265565</p>
                                    <img src={Constants.DELETEICON} style={{ height: "17px", flexShrink: 0, width: "16px" }} />
                                </Card>
                                <Card className='cardInfo'>
                                    <img src={Constants.WALLETLOGO} />
                                    <p>Test - fixehek493@stypedia.com HDFI655623265565</p>
                                    <img src={Constants.DELETEICON} style={{ height: "17px", flexShrink: 0, width: "16px" }} />
                                </Card>
                                <Nav className='addEventDiv'>
                                    <Nav.Link className='themeBtn' onClick={showBankDetailsModal}>
                                        Add Account
                                    </Nav.Link>
                                </Nav>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <BankDetailsForm
                visible={isBankDetailsModalVisible}
                onClose={handleAccountCancel}
                onSubmit={handleBankDetailsSubmit}
            />

            {isChangePasswordModalVisible && (
                <ChangePasswordForm
                    visible={isChangePasswordModalVisible}
                    onClose={handlePasswordCancel}
                />
            )}
        </div>
    )
}

export default Setting;
