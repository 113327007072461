import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useFetch from '../../Helper/useFetch';

const { Option } = Select; // Import Option from Select

const AdminEdition = (props) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const { id } = useParams();
    const [postLoading, setPostLoading] = useState(false);

    let api;
    let isEdit = false;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}event/${id}`, {}, false);
    const [eventData, isELoading, getAllEvent] = useFetch(`${apiUrl}admin/event`, [], true);

    if (id) {
        api = `${apiUrl}admin/event/${id}`;
        isEdit = true;
    } else {
        api = `${apiUrl}edition`;
    }

    const [file, setFile] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            getData(`${apiUrl}event/${id}`);
        }
    }, [id]);

    const response = data?.data;
    useEffect(() => {
        // Populate form fields with the event data when the component mounts
        if (isEdit && response) {
            form.setFieldsValue({
                eventName: response.name,
                eventDescription: response.description,
            });
            setFile(response.image);
        }
    }, [isEdit, response]);

    const onFinish = async (values) => {

        try {
            setPostLoading(true)
            const formData = new FormData();
            formData.append('eventId', values.eventName);
            formData.append('image', file);
            formData.append('description', values.eventDescription);
            formData.append('name', values.editionName);
            formData.append('price', values.price)

            console.log('Form values:', formData);

            // Determine the HTTP method based on whether it's an edit or create operation
            const method = isEdit ? 'PUT' : 'POST';

            // Send the formData to your API using the determined HTTP method
            const authToken = localStorage.getItem("Voice-Token")
            // const authToken = process.env.TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA';
            const response = await fetch(api, {
                method,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
                body: formData,
            });

            if (response.ok) {
                const jsonData = await response.json();
                console.log('API response:', jsonData);
                // Clear the form if it's a new event
                if (!isEdit) {
                    form.resetFields();
                    // setFile(null);
                }
                props.onModalClose();
            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error in onFinish:', error);
        }
        finally {
            setPostLoading(false)
        }
    };

    const handleChange = (e) => {
        setFile(e.target.files[0]);
    };

    const customRequest = ({ file, onSuccess, onError }) => {
        // You can implement your custom logic for uploading the file here.
        // Example:
        // uploadFileToServer(file)
        //   .then(() => onSuccess())
        //   .catch((error) => onError(error));
    };

    return (
        <Form
            form={form}
            name="event_form"
            onFinish={onFinish}
        // labelCol={{ span: 4 }}
        // wrapperCol={{ span: 16 }}
        >
            <Form.Item
                label="Event Name"
                name="eventName"
                rules={[{ required: true, message: 'Please enter the event name!' }]
                }
            >
                <Select placeholder="Select an event">
                    {eventData?.data.map((event) => (
                        <Option key={event.id} value={event.id}>
                            {event.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="Edition Name"
                name="editionName"
                rules={[{ required: true, message: 'Please enter the edition name!' }]
                }
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Practice Logo"
            >
                <Input type="file" placeholder="Practice Logo" onChange={handleChange} />
            </Form.Item>
            <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: 'Please enter the price!' }]
                }
            >
                <Input />
            </Form.Item>


            <Form.Item
                label="Event Description"
                name="eventDescription"
                rules={[
                    {
                        required: true,
                        message: 'Please enter the event description!',
                    },
                ]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit" className='openFormsBtn' disabled={postLoading}>
                    {isEdit ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AdminEdition;
