import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Space, Table, Tag } from 'antd';
import Constants from '../../Components/UiKit/Constants';
import { Pie } from "@ant-design/charts";
import useFetch from '../../Helper/useFetch';

const AdminOverView = (props) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}dashboard`, [], true);
    const result = data?.data

    const columns = [
        {
            title: 'Event ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'Category',
            dataIndex: 'categoryId',
            key: 'categoryId',
        },
        {
            title: 'Description',
            dataIndex: 'description ',
            key: 'description',
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate ',
            key: 'startDate',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
    ];
    const pieChartData = [
        {
            type: "voting",
            value: 40
        },
        {
            type: "edition",
            value: 25
        },
        {
            type: "withdrawl",
            value: 22
        },
        {
            type: "votes",
            value: 22
        },

    ];

    const config = {
        appendPadding: 10,
        data: pieChartData,
        angleField: "value",
        colorField: "type",
        radius: 1,
        innerRadius: 0.5,
        color: ['#ffe9d4', '#ffd1a0', '#ffb66e', '#ffa33d'],
        label: {
            type: "inner",
            offset: "-50%",
            content: "{value}",
            style: {
                textAlign: "center",
                fontSize: 14
            }
        },
        interactions: [{ type: "element-selected" }, { type: "element-active" }],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                formatter: function formatter() {
                    return `total\n134`;
                }
            }
        }
    };


    return (
        <>
            <div className='overViewCountWrapper'>
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON1} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Events</h4>
                            </div>
                            <div>
                                <h4 className='countNo'>{result?.events}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON2} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Voting</h4>
                            </div>
                            <div>
                                <h4 className='countNo'>{result?.votings}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON3} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Contestants</h4>
                            </div>
                            <div>
                                <h4 className='countNo'>{result?.contestants}</h4>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <div className='overViewCountBox'>
                            <div>
                                <img src={Constants.OVERVIEWICON4} alt='Over View' />
                            </div>
                            <div>
                                <h4>Total Edition</h4>
                            </div>
                            <div>
                                <h4 className='countNo'>{result?.editions}</h4>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sx={12} sm={6} md={4}>
                        <div className='overViewCountBox' style={{ display: "block" }}>
                            <div className='walletBoxHeader'>
                                <div>
                                    <h5>Wallet</h5>
                                </div>
                                <div>
                                    <h3>$ 20</h3>
                                </div>
                            </div>

                            <div className='availableAmtBox'>
                                Available <input type='number' placeholder='0.00' />
                            </div>

                            <div class="walletBtnBox">
                                <a to="/" role="button" class="themeBtn nav-link" tabindex="0">Withdraw Funds</a>
                                <a to="/" role="button" class="themeBtn nav-link" tabindex="0">Add Wallet</a>
                            </div>

                        </div>
                    </Col>
                    <Col sx={12} sm={6} md={8}>
                        <div className='overViewCountBox' style={{ justifyContent: "center" }}>
                            <Pie {...config} />
                        </div>
                    </Col>
                </Row>

                <div className='recentEvents'>
                    <div className='tableHead'>Recent Events</div>

                    <Table columns={columns} />

                </div>

            </div>
        </>
    )
};

export default AdminOverView;
