import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import "./TopPanel.css";
import Constants from '../../Components/UiKit/Constants';
import { jwtDecode } from "jwt-decode";
import useFetch from '../../Helper/useFetch';
import { useToken } from '../TokenProvider';

function TopPanel() {
    const location = useLocation();
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const currentPath = location.pathname;
    const decodedToken = useToken()
    const [data, isPLoading, getData] = useFetch(`${apiUrl}user/${decodedToken.userId}`, [], true);

    // Find the index of "/admin/" in the URL
    const adminIndex = currentPath.indexOf("/admin/");

    // useEffect(() => {

    //     getData(`${apiUrl}/user/${decodedToken.userId}`)

    // }, [])



    // console.log({ decodedToken })
    // If "/admin/" is found, extract the part after it
    const heading = adminIndex !== -1
        ? currentPath.substring(adminIndex + 7) // 7 is the length of "/admin/"
        : "Overview"; // Default heading if "/admin/" is not found

    // Capitalize the first letter of the extracted heading
    // const capitalizedHeading = heading.charAt(0).toUpperCase() + heading.slice(1);
    const capitalizedHeading = currentPath.split("/")[2].toUpperCase()


    return (
        <div className="topPanel">
            <Row className='align-items-center'>
                <Col xs={12} sm={6}>
                    <h4>{capitalizedHeading}</h4>
                </Col>
                <Col xs={12} sm={6}>
                    <div className='topNotificationBox'>
                        <img src={Constants.ADMINNOTIFICATION} alt='Notification' />
                        <div className='userProfile'>
                            <img src={Constants.USERPIC} alt='user image' className='userImg' /> {data?.data?.firstname} {data?.data?.lastname}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default TopPanel;
