import React, { useState } from "react";
import "./EventDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import Constants from "../../../Components/UiKit/Constants";
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import PathConstants from "../../../Helper/Routes/PathConstants";
import useFetch from "../../../Helper/useFetch";
import { DateFormatter } from "../../../Components/UiKit/DateFormatter";
import { Modal } from "antd";

const { confirm } = Modal; // Destructure Modal


const EventDetail = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const apiUrl = process.env.REACT_APP_VOICE_API
    const [data, isPLoading, getData] = useFetch(`${apiUrl}home/category/event/${id}`, [], true);
    const [showResultsPopup, setShowResultsPopup] = useState(false);
    // const data = [
    //     { "id": 1, "title": "Item 1" },
    //     { "id": 2, "title": "Item 2" },
    //     { "id": 3, "title": "Item 3" },
    //     { "id": 4, "title": "Item 4" },
    //     { "id": 5, "title": "Item 5" },
    //     { "id": 6, "title": "Item 6" },
    //     { "id": 7, "title": "Item 7" },
    //     { "id": 8, "title": "Item 8" },
    //     { "id": 9, "title": "Item 9" },
    //     { "id": 10, "title": "Item 10" },
    //     { "id": 11, "title": "Item 11" },
    //     { "id": 12, "title": "Item 12" }
    // ]

    const handleContestantLinkClick = (id, name, eventId) => {
        // Add your logic for Contestants link redirection
        navigate(PathConstants.CONTESTANTS.replace(":id", id), {
            state: {
                category: name,
                eventId: eventId
            }
        });
    };

    const handleResultsLinkClick = (id) => {
        // Show the results popup using Ant Design Modal
        confirm({
            title: 'Results Not Declared Yet',
            content: 'The results for this event have not been declared yet. Please check back later.',
            onOk() {
                setShowResultsPopup(false);
            },
        });
    };

    const getImageUrl = () => {

    }

    return (
        <>
            <div className="categoryBanner">
                <div className="container text-center">
                    <h3>{data?.data[0]?.event?.name}</h3>
                    <p>{data?.data[0]?.event?.description}</p>

                </div>
                <div className="categoryDateContainer">
                    <div className="categoryDate">{DateFormatter(data?.data[0]?.event?.fromTime)}</div>
                </div>
            </div>
            <div className="eventList">
                <div className='ongoingEventSection sectionPadding'>
                    <h2 className='heading'>Category</h2>
                    <Container>
                        <Row>
                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-3'>



                                    <div className="categoryBox"
                                        style={{ backgroundImage: `url(${apiUrl}${i.image})` }} crossOrigin="anonymous"
                                    >
                                        {/* <img src={`${apiUrl}${i.image}`} crossOrigin="anonymous" style={{ height: "100px", width: "210px" }} alt="EBELKFJ" /> */}
                                        <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                            {/* Contestants */}
                                            <span className="constestantLink" onClick={() => handleContestantLinkClick(i.id, i.name, i.eventId)}>
                                                Contestants
                                            </span>

                                            {/* Results */}
                                            <a className="constestantLink" onClick={() => handleResultsLinkClick(i.id)}>
                                                Results
                                            </a>
                                        </div>
                                        {/* <img crossOrigin="anonymous" src={`${apiUrl}${i.image}`} alt="Event" /> */}

                                        {/* Other card content */}
                                        <div className='categoryDetails'>
                                            <h5>{i.name}</h5>
                                        </div>
                                    </div>
                                </Col>


                            ))}

                        </Row>
                    </Container>
                    <img src={Constants.VECTOR1} alt="event" className='vector1Img' />
                </div>
            </div >
        </>
    );
};
export default EventDetail