import React, { useState } from 'react';
import useFetch from "../../Helper/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import useDeleteApi from '../../Helper/useDeleteApi';
import { Table, Button, Space, Modal, Form, Input } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import useDelete from '../../Helper/useDelete';
import NewPostApi from '../../Helper/NewPostApi';


const EditionList = ({ eventId }) => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate();
    const { id } = useParams();
    const [categoryName, setCategoryName] = useState("")
    const [recordId, setRecordId] = useState("")

    const [data, isPLoading, getData] = useFetch(`${apiUrl}edition`, [], true);
    const categoriesToUpdate = NewPostApi(`${apiUrl}edition/${recordId}`)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const deleteItem = useDelete();
    const [editEditionName, setEditEditionName] = useState('');
    const { categoriesLoading, categoryError, sendDeleteRequest } = useDeleteApi();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Edition Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Event Id',
            dataIndex: 'eventId',
            key: 'eventId',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="danger" onClick={() => handleDelete(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    const handleEdit = (record) => {
        setRecordId(record.id)
        setEditEditionName(record.name);
        setIsModalVisible(true);
    };

    const handleDelete = (params) => {
        console.log({ params })
        // confirmAlert({
        //     title: "Confirm delete",
        //     message: "Are you sure to delete ?",
        //     buttons: [
        //         {
        //             label: "Yes",
        //             onClick: () => {
        deleteItem(`${apiUrl}category/${params}`, getData, `${apiUrl}category/${params}`)

        // }
        //         },
        //         {
        //             label: "No",
        //             onClick: () => console.info(" cancelled."),
        //         },
        //     ],
        // });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleFormSubmit = async (values) => {
        try {
            await categoriesToUpdate.addOrUpdateData({ name: values.categoryName, eventId: id }, true);
            // Make an API request to update the category name using values.categoryName
            // After successful update, close the modal and update the data source or take appropriate action

            setIsModalVisible(false);
        } catch (error) {
            // Handle the error here, e.g., log it or show an error message to the user
            console.error(error);
        }
    };


    return (
        <div>
            <Table
                columns={columns}
                dataSource={data?.data}
            />
            <Modal
                title="Edit Category"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    onFinish={handleFormSubmit}
                    initialValues={{ editionName: editEditionName }}
                >
                    <Form.Item
                        label="Edition Name"
                        name="editionName"
                        rules={[{ required: true, message: 'Please enter a edition name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default EditionList;
