import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { Space, Table, Tag } from 'antd';
import Constants from '../../Components/UiKit/Constants';
import { Pie } from "@ant-design/charts";
import { useNavigate, useParams, Link } from "react-router-dom";
import useFetch from '../../Helper/useFetch';

const AdminEditionResult = (props) => {
    const { id } = useParams()
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}result/edition/${id}`, [], true);

    const columns = [
        {
            title: 'Contestant',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Stage Name',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'Category Name',
            dataIndex: 'categoryId',
            key: 'categoryId',
        },
        {
            title: 'Votes',
            dataIndex: 'description ',
            key: 'description',
        },
        {
            title: '%',
            dataIndex: 'startDate ',
            key: 'startDate',
        },

    ];




    return (
        <>
            <div className='overViewCountWrapper'>




                <div className='recentEvents'>
                    <div className='tableHead'>Result</div>

                    <Table columns={columns} />

                </div>

            </div>
        </>
    )
};

export default AdminEditionResult;
