const PathConstants = {
  HOME: "/",
  HOMEPAGE: "/home",
  EVENTS: "/events",
  EVENTSDETAIL: "/events/detail/:id",
  CONTESTANTS: "/events/detail/contestants/:id",
  CONTESTANTSVOTE: "/events/detail/contestants/vote/:id",
  ABOUT: "/about",
  RESULT: "/result",
  CONTACTUS: "/contactus",
  LOGIN: "/login",
};

export default PathConstants;
