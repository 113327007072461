import { useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useDelete = () => {
    const [isLoading, setIsLoading] = useState(false);

    const deleteItem = async (url, callback, callbackUrl) => {
        setIsLoading(true);

        try {
            const token = localStorage.getItem("Voice-Token")
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                // Handle successful deletion
                if (callback && callbackUrl) {
                    await callback(callbackUrl);
                }
                toast.success('Item deleted successfully!');
            } else {
                throw new Error('Request failed.');
            }
        } catch (error) {
            // Handle error
            console.error(error);
        }

        setIsLoading(false);
    };

    return deleteItem;
};

export default useDelete;
