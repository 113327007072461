import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, Button, DatePicker, TimePicker, Select } from 'antd';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../../Helper/useFetch';

const AdminEvents = () => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const { id } = useParams()
    const navigate = useNavigate()
    let api;
    let isEdit = false;
    const [postLoading, setPostLoading] = useState(false);
    const [data, isPLoading, getData] = useFetch(`${apiUrl}event/${id}`, {}, false);

    if (id) {
        api = `${apiUrl}admin/event/${id}`;
        isEdit = true;
    } else {
        api = `${apiUrl}admin/event`;
    }

    const [file, setFile] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            getData(`${apiUrl}event/${id}`)
        }

    }, [id])

    const response = data?.data
    useEffect(() => {
        // Populate form fields with the event data when the component mounts
        if (isEdit && response) {
            form.setFieldsValue({
                eventName: response.name,
                eventDescription: response.description,
                eventDate: moment(response.fromTime), // Set the entire date from fromTime
                fromTime: moment(response.fromTime, 'HH:mm'),
                toTime: moment(response.toTime, 'HH:mm'),
            });
            setFile(response.image)
        }
    }, [isEdit, response]);

    const onFinish = async (values) => {
        try {
            setPostLoading(false)
            const formData = new FormData();
            formData.append('name', values.eventName);
            formData.append('image', file);
            formData.append('description', values.eventDescription);
            formData.append('type', values.type);
            // formData.append('date', values.eventDate.format('YYYY-MM-DD'));
            formData.append('fromTime', values.fromTime);
            formData.append('toTime', values.toTime);

            console.log('Form values:', formData);

            // Determine the HTTP method based on whether it's an edit or create operation
            const method = isEdit ? 'PUT' : 'POST';


            // Send the formData to your API using the determined HTTP method
            // const authToken = process.env.TOKEN || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImVtYWlsIjoib3JnYW5pemVyQHRlc3QuY29tIiwicm9sZSI6Ik9SR1JBTklaRVIiLCJpYXQiOjE2OTY1MDYyMjd9.oZsOcIzpO9CePiKJ5A-XjZFjx9-eIq0AigSdGrq5exA'
            const authToken = localStorage.getItem("Voice-Token")
            const response = await fetch(api, {
                method,
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
                body: formData,
            });

            if (response.ok) {
                const jsonData = await response.json();
                console.log('API response:', jsonData);
                // Clear the form if it's a new event
                if (!isEdit) {
                    form.resetFields();
                    // setFile(null);
                }
                navigate("/admin/events")

            } else {
                console.error('API request failed');
            }
        } catch (error) {
            console.error('Error in onFinish:', error);
        } finally {
            setPostLoading(false)
        }
    };

    const handleChange = (e) => {
        setFile(e.target.files[0]);
    };
    const validateTimeRange = async (_, value) => {
        const fromTime = form.getFieldValue('fromTime');
        const toTime = value;

        if (fromTime && toTime && fromTime.isAfter(toTime)) {
            return Promise.reject(new Error('From Time must be before To Time'));
        }

        return Promise.resolve();
    };

    return (
        <Form
            form={form}
            name="event_form"
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
        >
            <Form.Item
                label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>Event Name</span>}
                name="eventName"
                rules={[{ required: true, message: 'Please enter the event name!' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>Event Type</span>}
                name="type"
                rules={[{ required: true, message: 'Please enter the event type!' }]}
            >
                {/* Replace Input with Select for dropdown */}
                <Select placeholder="Select event type">
                    <Select.Option value="award">Award</Select.Option>
                    <Select.Option value="pageant">Pageant</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>Practice Logo</span>}>
                <Input type="file" placeholder="Practice Logo" onChange={handleChange} />
            </Form.Item>

            <Form.Item
                label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>Event Description</span>}
                name="eventDescription"
                rules={[
                    {
                        required: true,
                        message: 'Please enter the event description!',
                    },
                ]}
            >
                <Input.TextArea />
            </Form.Item>

            {/* <Form.Item
                label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>Event Date</span>}
                name="eventDate"
                rules={[
                    {
                        required: true,
                        message: 'Please select the event date!',
                    },
                ]}
            >
                <DatePicker />
            </Form.Item> */}

            <Form.Item
                label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>From Time</span>}
                name="fromTime"
                rules={[
                    {
                        required: true,
                        message: 'Please select the start time!',
                    },
                    {
                        validator: validateTimeRange,
                    },
                ]}
            >
                <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: '100%' }}
                />
            </Form.Item>

            <Form.Item
                label={<span style={{ color: 'white', background: 'grey', padding: '8px', borderRadius: '8px' }}>To Time</span>}
                name="toTime"
                rules={[
                    {
                        required: true,
                        message: 'Please select the end time!',
                    },
                    {
                        validator: validateTimeRange,
                    },
                ]}
            >
                <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: '100%' }}
                />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button type="primary" htmlType="submit" className='openFormsBtn' disabled={postLoading}>
                    {isEdit ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    );


};

export default AdminEvents;
