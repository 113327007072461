import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Faqs = () => {
    const data = [
        {
            id: 1,
            question: 'How are the readings completed?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 2,
            question: 'How can you complete a reading online without seeing me?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 3,
            question: 'What if I book a reading to test you and you get it wrong?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 4,
            question: 'Are you ever wrong?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 5,
            question: 'Well, how accurate are you then?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 6,
            question: 'Can I get a free reading?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 7,
            question: 'Do you accept international payments?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 8,
            question: 'Why have I been charged $28 when the price said £20?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
        {
            id: 9,
            question: 'When will I receive my reading?',
            answer: 'Lorem ipsum dolor sit amet consectetur. Augue sed magna nisi pellentesque. Nunc tellus sit sed pharetra id duis ut. Morbi ultrices consequat habitant ut. Adipiscing bibendum praesent mauris ullamcorper in id. Nisi urna ac dolor aliquet id amet lectus amet enim. Lobortis ac suscipit integer nullam vivamus adipiscing pretium sit consequat. Vel suspendisse et sit et id luctus. Gravida tortor dolor eget ligula ipsum. Hendrerit fringilla pellentesque vulputate urna non vulputate morbi tristique. Neque convallis eget rhoncus condimentum egestas habitant magna congue.',
        },
    ];

    return (
        <Accordion>
            {data?.map((faq, index) => (
                <Accordion.Item key={faq.id} eventKey={faq.id.toString()}>
                    <Accordion.Header>
                        {faq.question}
                    </Accordion.Header>
                    <Accordion.Body style={{ background: 'grey', color: 'white' }}>
                        {faq.answer}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default Faqs;
