import React, { useState } from 'react';
import { Table, Modal, Form, Input, Button, Upload, Select } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import useFetch from '../Helper/useFetch';
import useApiPost from '../Helper/useApiPost';
import { render } from '@testing-library/react';

const { Option } = Select;

const CommisionList = () => {
    const columns = [
        {
            title: 'Organizer',
            dataIndex: 'organizer',
            key: 'organizer',
            render: (data) => {
                return data.firstname + " " + data.lastname
            }
        },
        // {
        //     title: 'Event ',
        //     dataIndex: 'name',
        //     key: 'name',
        // },
        // {
        //     title: 'Edition',
        //     dataIndex: 'eventId',
        //     key: 'eventId',
        // },
        {
            title: 'Rate of Commision',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const [data, isPLoading, getData] = useFetch(`${apiUrl}/user/organizer`, [], true);
    const [isLoading, error, responseData, postData] = useApiPost(`${apiUrl}/commission`);
    const [commissionData, isCLoading, getCommisionData] = useFetch(`${apiUrl}commission`, [], true);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = async (values) => {
        // Handle form submission here
        console.log('Received values:', values);
        await postData(values)
        setIsModalVisible(false); // Close the modal after submission
    };

    return (
        <div>
            <Nav className='addEventDiv'>
                <button to="/admin/events/add" className='themeBtn' onClick={showModal}>
                    Add Commission Rates
                </button>
            </Nav>
            <Table columns={columns} dataSource={commissionData?.data} />

            {/* Modal for Complaint Form */}
            <Modal
                title="Add Commission Rates"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form name="commissionForm" onFinish={onFinish}>
                    <Form.Item
                        name="organizerId"
                        label="Organizer"
                        rules={[{ required: true, message: 'Please select an organizer!' }]}
                    >
                        <Select placeholder="Select organizer">
                            {data?.data.map(i => (
                                <Option key={i.id} value={i.id}>{i.firstname} {i.lastname}</Option>
                            ))}
                        </Select>

                    </Form.Item>

                    <Form.Item
                        name="rate"
                        label="Rate of Commission"
                        rules={[{ required: true, message: 'Please enter the rate of commission!' }]}
                    >
                        <Input placeholder="Enter rate of commission" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CommisionList;
