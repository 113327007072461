import React, { useEffect, useState } from 'react';

import Container from 'react-bootstrap/Container';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Constants from './Constants';
import MobileMenu from './MobileMenu';
import PathConstants from './../../Helper/Routes/PathConstants';


const Header = () => {
  const [activeLink, setActiveLink] = useState('Home');
  const [activeButton, setActiveButton] = useState("Login");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleNavLinkClick = (linkName) => {
    setActiveLink(linkName);
    sessionStorage.setItem('activeLink', linkName);
  };

  var parse = require('html-react-parser');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/ecofriendly?scrollTo=nonSmokingHospital');
  };

  const handleRedirectionClick = (path, e) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  }

  const eventNavigate = () => {
    navigate("/events")
  }

  const handleClickForChild = (path, e) => {
    e.stopPropagation();
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  useEffect(() => {
    const storedActiveLink = sessionStorage.getItem('activeLink');
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname ==
      '/login') {
      setActiveLink("")
    }
  }, [window.location.pathname])


  return (
    <>
      <div className='headerSec'>

        <div className='navBarTop'>
          <Container>
            <div className="logoBox">
              <div className='head-top-logo' onClick={() => handleRedirectionClick(PathConstants.HOME)}><img src={Constants.LOGO} alt="Logo" className='logo' /></div>

              <div className='topBar'>
                <Navbar expand="lg" className="bg-body-tertiary">
                  <Nav>
                    <Nav.Link
                      to="/"
                      className={activeLink === 'Home' ? 'active' : ''}
                      onClick={() => { handleNavLinkClick('Home'); navigate("/") }}
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      to="/about"
                      className={activeLink === 'About' ? 'active' : ''}
                      onClick={() => { handleNavLinkClick('About'); navigate("/about") }}
                    >
                      About
                    </Nav.Link>
                    <Nav.Link
                      to="/events"
                      className={activeLink === 'Events' ? 'active' : ''}
                      onClick={() => { handleNavLinkClick('Events'); eventNavigate() }}
                    >
                      Events
                    </Nav.Link>
                    <Nav.Link
                      to="/voting"
                      className={activeLink === 'Voting' ? 'active' : ''}
                      onClick={() => handleNavLinkClick('Voting')}
                    >
                      Voting
                    </Nav.Link>
                    <Nav.Link
                      to="/contact"
                      className={activeLink === 'Contact Us' ? 'active' : ''}
                      onClick={() => { handleNavLinkClick('Contact Us'); navigate("/contactus") }}
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </div>

              <div className='head-top-links'>
                <div className='login-register-box'>
                  <button type='button' className={`loginBtn ${activeButton === "Login" ? "activeBtn" : ""}`} onClick={() => { console.log("vc"); navigate("/login"); handleButtonClick("Login") }}>Login/Register</button>
                  {/* <button type='button' className={`loginBtn ${activeButton === "Register" ? "activeBtn" : ""}`} onClick={() => { navigate("/login"); handleButtonClick("Register") }}>Register</button> */}
                </div>
                {/* <div className='login-register-box'>
                  <Link to="/login" className='loginBtn activeBtn'>Login</Link>
                  <Link to="/register" className='loginBtn'>Register</Link>
                </div> */}
              </div>


              <div className='mobileNav'>
                <MobileMenu />
              </div>

            </div>
          </Container>

        </div >
      </div >
    </>
  )
}

export default Header