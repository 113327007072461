import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'antd';
import { Modal, Button } from 'antd';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import useFetch from '../../Helper/useFetch';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Constants from '../../Components/UiKit/Constants';
import AdminEdition from './AdminEdition';
import { useUserContext } from '../../Helper/useUserContext';
import AdminEditionContestant from './AdminEditionContestant';
import useDelete from '../../Helper/useDelete';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const AdminEditionList = () => {
    // const [data, setData] = useState([]);
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate()
    const { isLogging } = useUserContext();
    const deleteItem = useDelete();

    const [data, isPLoading, getData] = useFetch(`${apiUrl}/edition`, [], true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isContestantModalVisible, setIsConstestantModalVisible] = useState(false);
    // const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedEditionId, setSelectedEditionId] = useState(null); // Step 1: State to store selected event ID

    const handleOpenModal = (eventId) => { // Step 2: Update state with event ID
        setSelectedEventId(eventId);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setSelectedEventId(null); // Clear the selected event ID when closing the modal
        setIsModalVisible(false);
    };

    const handleContestantModal = (eventId, editionId) => {
        setSelectedEventId(eventId);
        setSelectedEditionId(editionId)
        setIsConstestantModalVisible(true)

    }

    const handleContestantCloseModal = (eventId, editionId) => {

        setIsConstestantModalVisible(false)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Event Name',
            dataIndex: 'eventName',
            key: 'eventName',
        },
        {
            title: 'Event Date',
            dataIndex: 'eventDate',
            key: 'eventDate',
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
        },
    ];

    const getImgUrl = (url) => {

        if (url?.startsWith('/')) {
            url = url?.substring(1)
            return apiUrl + url
        }
        return apiUrl + url

    }


    const editionDelete = (params) => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    // onClick: deleteItem(`${apiUrl}edition/${params}`, getData, `${apiUrl}edition/${params}`),
                },
                {
                    label: 'No',
                    onClick: () => { }, // Do nothing on "No" click
                },
            ],
        });
    };

    return (
        // isLogging && (
        <>


            {/* eventBanner */}

            <div className='adminEventSearch'>
                <div>
                    <img src={Constants.EVENTSEARCHICON} alt="Search Icon" />
                    <input type="text" placeholder="Search" />
                </div>
                <div>
                    <div className='addEventDiv'>
                        <Nav className='addEventDiv'>
                            <Nav.Link to="/admin/events/add" onClick={showModal} className='themeBtn'>
                                Add Edition
                            </Nav.Link>
                        </Nav>
                    </div>
                </div>
            </div>

            <div className="eventList">
                <div className='ongoingEventSection'>
                    <Container>
                        <Row>
                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-4'>
                                    <div className='eventBox'>
                                        <div className='contestantBox'>Paid</div>
                                        <button type="button" className="eventEditBtn" style={{ right: '50px' }} onClick={() => editionDelete(i.id)}> <img src={Constants.DELETEICON} /> </button>
                                        <button type="button" className="eventEditBtn" onClick={() => navigate(`/admin/edition/constestant/${i.id}`)}> <img src={Constants.EDITICON} /> </button>
                                        <img src={getImgUrl(i.image)} alt="event" crossOrigin="anonymous" className='adminEventImg' />

                                        <div className='eventNameBox'>
                                            <div className='adminEventName'>
                                                <h4>{i.name}</h4>
                                                <h4 style={{ "fontSize": "15px" }}>{i?.event?.name}</h4>
                                            </div>
                                            <div className='eventStatus'>
                                                <div className='statusBox'>Rejected</div>
                                                <div className='statusBox' style={{ "marginTop": "10px", "color": "green" }}>Activate</div>
                                                <div className='statusBox' style={{ "marginTop": "10px", "color": "yellow" }}>Close</div>
                                            </div>
                                        </div>

                                        <div className='adminEventDtls'>
                                            {/* <div className='dateBox'>
                                                <span>{new Date(i.fromTime).toLocaleString('default', { month: 'short' })}</span>
                                                {new Date(i.fromTime).getDate()}
                                            </div> */}
                                            {/* <div>
                                                {new Date(i.fromTime).toLocaleTimeString()}
                                            </div> */}

                                            <div>
                                                {/* <h5>{i.name}</h5> */}
                                                <p>{i.description}</p>
                                                <button className='openFormsBtn' onClick={() => navigate(`/admin/edition/result/${i.id}`)}>Result</button>

                                            </div>
                                        </div>

                                        {/* <div className='adminTag'>
                                            <div>Category</div>
                                            <div>Edition</div>
                                        </div> */}
                                        <button className='openFormsBtn' onClick={() => navigate(`/admin/edition/constestant/${i.id}`)}>View Contestants</button>
                                        <button className='openFormsBtn' onClick={() => handleContestantModal(i.eventId, i.id)} style={{ "marginLeft": "10px" }}>Add Contestant</button>

                                        {/* <button className='openFormsBtn' onClick={() => handleOpenModal(i.id)}>Add Edition</button> */}
                                        {/* <button className='openFormsBtn'>Open Forms</button> */}
                                        {/* <button className='themBtn'>Activate</button> */}
                                    </div>
                                </Col>
                            ))}

                        </Row>
                    </Container>

                </div>
            </div >
            <Modal
                title="Add Edition"
                visible={isModalVisible}
                onCancel={handleCloseModal}
                footer={[
                    <Button key="back" onClick={handleCloseModal}>
                        Cancel
                    </Button>,
                ]}
            >
                {/* Render the AdminEdition component in the modal */}
                <AdminEdition eventId={selectedEventId} onModalClose={handleCloseModal} />
            </Modal>
            <Modal
                title="Add Contestant"
                visible={isContestantModalVisible}
                onCancel={handleContestantCloseModal}
                footer={[
                    <Button key="back" onClick={handleContestantCloseModal}>
                        Cancel
                    </Button>,
                ]}
            >
                {/* Render the AdminEdition component in the modal */}
                <AdminEditionContestant eventId={selectedEventId} editionId={selectedEditionId} />
            </Modal>


        </>
        // )
    );
};

export default AdminEditionList;
