import React from "react";
import PathConstants from "./PathConstants";
import ExploreEvents from "../../Pages/Home/Events/ExploreEvents";
import EventDetail from "../../Pages/Home/Events/EventDetail";
import Contestants from "../../Pages/Home/Events/Contestants";
import ContestantVote from "../../Pages/Vote/ContestantVote";
import About from "../../Pages/About/About";
import Result from "../../Pages/Result/Result";
import ContactUs from "../../Pages/ContactUs/ContactUs";
import NotFound from "../../Pages/NotFound";
import AdminDashboard from "../../AdminPanel/AdminDashBoard";
import LoginSignup from "../../Pages/LoginSignup/LoginSignup";
const Home = React.lazy(() => import("../../Pages/Home"));

const routes = [
  { path: PathConstants.HOME, element: <Home /> },
  { path: PathConstants.HOMEPAGE, element: <Home /> },
  { path: PathConstants.EVENTS, element: <ExploreEvents /> },
  { path: PathConstants.EVENTSDETAIL, element: <EventDetail /> },
  {
    path: PathConstants.CONTESTANTS, element: <Contestants />
  },
  {
    path: PathConstants.CONTESTANTSVOTE, element: <ContestantVote />
  },
  {
    path: PathConstants.ABOUT, element: <About />
  },
  {
    path: PathConstants.RESULT, element: <Result />
  },
  {
    path: PathConstants.CONTACTUS, element: <ContactUs />
  },
  {
    path: PathConstants.LOGIN, element: <LoginSignup/>
  },
  // {
  //   path: "/admin", element: <AdminDashboard />
  // },
  // {
  //   path: "/admin/dashboard", element: <About />
  // },
  // {
  //   path: "/admin/user", element: <ContactUs />
  // },
  { path: "*", element: <NotFound /> },
];

export default routes;
