import React, { useState, useEffect } from "react";
import "./LoginSignup.css";
import Container from 'react-bootstrap/Container';
import { Form, Input, Button } from "antd";
import { Row, Col } from 'react-bootstrap';
import Constants from "../../Components/UiKit/Constants";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useNavigate } from "react-router-dom";
import useApiPost from '../../Helper/useApiPost'
import Password from "antd/es/input/Password";
import { useUserContext } from "../../Helper/useUserContext";

const LoginSignup = () => {
    const apiUrl = process.env.REACT_APP_VOICE_API;
    const navigate = useNavigate()
    const { isLogging, setLogging } = useUserContext();
    const [enteredOTP, setEnteredOtp] = useState('')

    const [isLoading, error, responseData, postData] = useApiPost(`${apiUrl}user/organizer`);
    const [isLoginLoading, loagingError, responseLoginData, postLoginData] = useApiPost(`${apiUrl}auth/login`);
    const [isOTPLOADING, loagingOTPError, responseOTPData, postOTPData] = useApiPost(`${apiUrl}auth/forget`);
    const [isCHeckLOADING, loagingCheckError, responseCheckOTPData, postCheckOTPData] = useApiPost(`${apiUrl}/auth/verify-otp`);
    const [isRLOADING, loagingRError, responseResendOTPData, postResendOTPData] = useApiPost(`${apiUrl}auth/resend-otp`);

    console.log({ responseLoginData })

    const onFinish = async (values) => {

        // Handle form submission here
        console.log("Form values:", values);
        await postData(values)

    };

    const onSignIn = async (values) => {
        console.log("dgxcbxc")
        await postLoginData(values)
        // navigate("/admin/events")

    }

    const onSignUp = async (values) => {
        const signUpValues = {
            firstname: values.registerfullName.split(' ')[0],
            lastname: values.registerfullName.split(' ')[1],
            email: values.registerEmail,
            password: values.registerConfirmPassword,
            phone: values.registerMobileNo

        }
        console.log("Form values:", signUpValues);
        await postData(signUpValues)

        // window.location.reload();

    }

    const sendOTP = async (values) => {
        console.log("ffgkgj")
        await postOTPData({ "input": enteredOTP })
    }

    const checkOTP = async (values) => {
        const finalOtp = values.otp.slice(1).join('')
        console.log(finalOtp)
        await postCheckOTPData({ "input": enteredOTP, "otp": finalOtp })


    }

    const resendOTP = async (values) => {
        await postResendOTPData({ "input": enteredOTP })
    }

    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('registerPassword') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords do not match.'));
        },
    });

    const [phone, setPhone] = useState(false)
    const [showForgetPassword, setShowForgetPassword] = useState(false)
    const [showOtpModel, setShowOtpModel] = useState(false)

    useEffect(() => {
        // if (isLoading) {
        // 	return (
        // 		<div className='loader_center'>
        // 			<Spin className="for_fetch_failed" />
        // 		</div>
        // 	)
        // }
        if (responseLoginData) {
            if (responseLoginData.status === 200) {
                localStorage.setItem("Voice-Token", (responseLoginData?.data.token))
                setLogging(true);
                responseLoginData?.data?.role === "SUPER_ADMIN" ? navigate("/superadmin/overview") : navigate("/admin/overview")
            }
        }

    }, [responseLoginData])
    useEffect(() => {
        if (responseCheckOTPData) {
            if (responseCheckOTPData?.success) {
                localStorage.setItem("Voice-Token", responseCheckOTPData?.data?.token)
                navigate("/admin/overview");
            } else {
                alert("OTP not matched. Please try again.");
            }
        }

    }, [responseCheckOTPData]);


    useEffect(() => {
        if (responseData) {
            if (responseData?.success) {
                localStorage.setItem("Voice-Token", responseData?.data?.token)
                navigate("/admin/overview");
            } else {
                alert("Please try again.");
            }

        }

    }, [responseData])

    return (
        <div className="LoginSignup">
            <Container>
                <Row className="align-items-center">
                    <Col className='col-12 col-sm-12 col-lg-6'>
                        <div className="loginLeftCol">
                            <div className="textBoxLogin">
                                <h4>“Lorem ipsum dolor sit amet consectetur Faucibus aenean tortor rhoncus rhoncus”</h4>
                                <p>Lorem ipsum dolor sit amet consectetur. Adipiscing tortor laoreet ipsum venenatis varius quam. Nunc laoreet non nibh molestie. Ut neque mauris sed rhoncus sit sed ante tellus.</p>
                            </div>

                            <img src={Constants.LOGINIMG} alt="Login icon" className="img-fluid" />
                        </div>
                    </Col>
                    <Col className='col-12 col-sm-12 col-lg-6'>

                        <div className="loginRightCol">

                            {!showOtpModel && !showForgetPassword && <div div className="loginOptionBox">
                                <h2>Welcome Back</h2>
                                <p>Sign In using Email to continue to Admin</p>

                                <Tabs defaultActiveKey="SignIn" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="SignIn" title="Sign In">

                                        <Form onFinish={onSignIn} className="userLoginInput" style={{ display: phone ? "none" : "block" }}>
                                            <Form.Item name="email" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter your email",
                                                    },
                                                ]}
                                            >
                                                <div>
                                                    <img src={Constants.LOGINEMAILICON} alt="Search Icon" className="inputIcon" />
                                                    <Input placeholder="Enter your email" /></div>
                                            </Form.Item>

                                            <Form.Item name="password" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Enter your password",
                                                    },
                                                ]}
                                            >
                                                <div>    <img src={Constants.LOGINPASSICON} alt="Search Icon" className="inputIcon" />
                                                    <Input placeholder="Enter your password" type={"password"} />
                                                </div>

                                            </Form.Item>

                                            <div className="forgotPassBox">
                                                <Link onClick={() => setShowForgetPassword(true)}>Forgot Password?</Link>
                                            </div>

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="signInBtn" disabled={isLoginLoading}>Sign In</Button>
                                            </Form.Item>

                                            <div className="otherLoginOpt">
                                                <p className="mb-4">Sign In with <Link onClick={() => setPhone(!phone)}>Phone No.</Link> or</p>
                                                <div className="text-center loginSocialIcon">
                                                    {/* <Link to="/"><img src={Constants.FBICON} alt="FB icon" /></Link>
                                                        <Link to="/"><img src={Constants.INSTAICON} alt="Insta icon" /></Link> */}
                                                </div>
                                            </div>

                                        </Form>

                                        <Form onFinish={onFinish} className="userLoginInput" id="loginWithMobile" style={{ display: phone ? "block" : "none" }}>

                                            <Form.Item name="mobile-number" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Enter your number",
                                                    },
                                                ]}
                                            >
                                                <img src={Constants.LOGINPASSICON} alt="Search Icon" className="inputIcon" />
                                                <Input placeholder="Enter your number" style={{ marginBottom: '10px' }} />
                                                <small className="text-white" style={{ fontSize: '14px' }}>A 5 digit OTP will be send via SMS to verify your mobile number.</small>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="signInBtn">Continue</Button>
                                            </Form.Item>

                                            <div className="otherLoginOpt">
                                                <p className="mb-4">Sign In with <Link onClick={() => setPhone(!phone)}>Email</Link> or</p>
                                                <div className="text-center loginSocialIcon">
                                                    <Link to="/"><img src={Constants.FBICON} alt="FB icon" /></Link>
                                                    <Link to="/"><img src={Constants.INSTAICON} alt="Insta icon" /></Link>
                                                </div>
                                            </div>

                                        </Form>

                                    </Tab>
                                    <Tab eventKey="Sign Up" title="Sign Up">

                                        <Form onFinish={onSignUp} className="userLoginInput">
                                            <Form.Item name="registerfullName" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter Full Name",
                                                    },
                                                ]}
                                            >
                                                <div>
                                                    <img src={Constants.USERICON} alt="user Icon" className="inputIcon" />
                                                    <Input placeholder="Enter Full Name" />
                                                </div>
                                            </Form.Item>



                                            <Form.Item name="registerBusiness" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter Business/Organisation Name",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter Business/Organisation Name" />
                                            </Form.Item>

                                            <Form.Item name="registerMobileNo" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter Mobile No.",
                                                    },
                                                ]}
                                            >
                                                <div>
                                                    <img src={Constants.CALLICON} alt="Search Icon" className="inputIcon" />
                                                    <Input placeholder="Enter Mobile No." />
                                                </div>
                                            </Form.Item>
                                            <Form.Item name="registerEmail" className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: "Enter Email",
                                                    },
                                                ]}
                                            >
                                                <div>
                                                    <img src={Constants.LOGINEMAILICON} alt="Search Icon" className="inputIcon" />
                                                    <Input placeholder="Enter Email" />
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                name="registerPassword"
                                                className="innerInputBox"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter your password',
                                                    },
                                                ]}
                                            >
                                                <div>
                                                    <img src={Constants.LOGINPASSICON} alt="Search Icon" className="inputIcon" />
                                                    <Input type="password" placeholder="Enter your password" />
                                                </div>
                                            </Form.Item>

                                            <Form.Item
                                                name="registerConfirmPassword"
                                                className="innerInputBox"
                                                dependencies={['registerPassword']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter Confirm Password',
                                                    },
                                                    validateConfirmPassword,
                                                ]}
                                            >
                                                <div>
                                                    <img src={Constants.LOGINPASSICON} alt="Search Icon" className="inputIcon" />
                                                    <Input type="password" placeholder="Enter Confirm Password" />
                                                </div>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="signInBtn" disabled={isLoading} >Sign Up</Button>
                                            </Form.Item>

                                            {/* <div className="otherLoginOpt">
                                                <p className="mb-4">Sign Up with</p>
                                                <div className="text-center loginSocialIcon">
                                                    <Link to="/"><img src={Constants.FBICON} alt="FB icon" /></Link>
                                                    <Link to="/"><img src={Constants.INSTAICON} alt="Insta icon" /></Link>
                                                </div>
                                            </div> */}

                                        </Form>

                                    </Tab>
                                </Tabs>
                            </div>}
                            === === loginOptionBox === ===
                            {showOtpModel ? <div className="loginOTPBox" style={{ display: "block" }}>
                                <h2>OTP Verification</h2>
                                <p>Enter the OTP you recieved</p>
                                <Form onFinish={checkOTP} className="userLoginInput" id="loginWithMobile">
                                    <>
                                        <div className="otpInputBox">
                                            {[1, 2, 3, 4, 5, 6].map((index) => (
                                                <div key={index}>
                                                    <Form.Item
                                                        name={['otp', index]}
                                                        className="innerInputBox"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Enter OTP',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>

                                                </div>


                                            ))}
                                        </div>
                                        <button onClick={resendOTP}>Resend OTP</button>
                                    </>
                                    {/* <div className="otpInputBox">
                                        <div>
                                            <Form.Item name="otp" className="innerInputBox"
                                                rules={[{
                                                    required: true, message: "Enter OTP"
                                                }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item name="otp" className="innerInputBox"
                                                rules={[{
                                                    required: true, message: "Enter OTP"
                                                }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item name="otp" className="innerInputBox"
                                                rules={[{
                                                    required: true, message: "Enter OTP"
                                                }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item name="otp" className="innerInputBox"
                                                rules={[{
                                                    required: true, message: "Enter OTP"
                                                }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </div>

                                        <div>
                                            <Form.Item name="otp" className="innerInputBox"
                                                rules={[{
                                                    required: true, message: "Enter OTP"
                                                }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </div>
                                    </div> */}
                                    <small className="text-white" style={{ fontSize: '14px' }}>Enter the OTP you received</small>
                                    <Form.Item style={{ marginTop: '40px' }}>
                                        <Button type="primary" htmlType="submit" className="signInBtn" disabled={isLoginLoading} >Sign In</Button>
                                    </Form.Item>
                                </Form>
                            </div> :
                                <div className="forgotPasswordBox" style={{ display: showForgetPassword ? "block" : "none" }}>
                                    <h2>Forgot Password</h2>
                                    <p>Enter the email or phone number you used when you joined and we'll send you temporary password.</p>
                                    <Form className="userLoginInput" >
                                        <Form.Item name="input" className="innerInputBox"
                                            rules={[
                                                {
                                                    required: true, message: "Enter Email or Phone no."
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Enter Email or Phone no." onChange={(e) => setEnteredOtp(e.target.value)} />
                                        </Form.Item>

                                        <Form.Item style={{ marginTop: '45px' }}>
                                            <Button type="primary" htmlType="submit" className="signInBtn" onClick={() => { setShowOtpModel(true); sendOTP() }}>Reset Password</Button>
                                        </Form.Item>

                                    </Form>
                                </div>}
                            {/* === === loginOTPBox === === */}



                        </div>

                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default LoginSignup;